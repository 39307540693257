import React from 'react';
import './Learnmorelink.css';

const Learnmorelink = (props) => {
  const source = props.source;
  return (
    <>
      {source?.status === 'show' && (
        <a
          className="learn-more-link w-inline-block"
          href={source.url}
          map=""
          map-value="link"
          rel="noopener noreferrer"
          target="_blank"
          title={source.url}
        >
          <div className="text-block-37">{source.displayName}</div>
        </a>
      )}

      {source?.status === 'disable' && (
        <div className="learn-more-link w-inline-block">
          <div title={source.url} className="text-block-37">
            {source.displayName}
          </div>
        </div>
      )}
    </>
  );
};

export default Learnmorelink;
