import React from 'react';
import useApi from '../api/base/useApi';
import { getTenant } from '@iblai/ibl-web-react-common';
import { useDispatch } from 'react-redux';
import { navigationActions } from '../../lib/redux/navigation/slice';

export const useTenantCustomization = () => {
  const api = useApi();
  const dispatch = useDispatch();

  const handleGetTenantMetaData = async () => {
    const tenant = getTenant();
    const url = `/api/core/orgs/${tenant}/metadata/`;
    const response = await api.get(url);
    if (response?.data) {
      //use when metadata not empty
      if (Object.keys(response?.data?.metadata || {}).length > 0) {
        dispatch(
          navigationActions.tenantCustomizationUpdated({
            enabled: true,
            metadata: response?.data?.metadata,
          })
        );
      }
    }
  };

  return {
    handleGetTenantMetaData,
  };
};
