import React from 'react';
import './Settingsmenudropdownbox.css';
import { useTriggers } from '../../hooks/navigation';

const Settingsmenudropdownbox = (props) => {
  const {
    handleMentorsMenuBtnClick,
    handleLLMProviderMenuBtnClick,
    handleUsersMenuBtnClick,
  } = useTriggers();
  return (
    <nav className="header-settings-dropdown-container settings-dropdown w-dropdown-list">
      <div className="w-layout-vflex user-menu-dropdown-block black-bg-dark-mode all-whiten-in-dark-mode whiten-border-in-dark-mode user-menu-settings-dropdown">
        <a
          className="user-menu-link greyish-bg-in-darkmode theme-switcher-container user-menu-dark-mode w-inline-block"
          data-w-id="1cb1be33-4bc7-6049-ca30-0af89add764d"
          href="#!"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/moon-svgrepo-com.svg"
          />
          <div className="user-menu-label">Use Dark Theme</div>
        </a>
        <a
          className="user-menu-link greyish-bg-in-darkmode theme-switcher-container user-menu-light-mode w-inline-block"
          data-w-id="f3a0f1ea-e9d3-6429-e0b1-6038644d6205"
          href="#"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/sun-1.svg"
          />
          <div className="user-menu-label">Use Light Theme</div>
        </a>
        <div className="w-layout-hflex switcher-container switcher-mobile-container">
          <h5 className="switch-label-txt whiten-in-dark-mode">LEARNER</h5>
          <div className="switch-html-embed w-embed">
            <label className="switch learner-instructor-switch">
              <input defaultChecked="" type="checkbox" />
              <span className="slider round">
                <img
                  alt=""
                  className="left-img"
                  src="/custom-assets/img/graduation-cap.svg"
                />
                <img
                  alt=""
                  className="right-img"
                  src="/custom-assets/img/presentation.svg"
                />
              </span>
            </label>
          </div>
          <h5 className="switch-label-txt whiten-in-dark-mode instructor-label">
            INSTRUCTOR
          </h5>
        </div>
        <a
          className="user-menu-link greyish-bg-in-darkmode w-inline-block"
          data-w-id="7dcf2871-4194-e627-3182-c2c3816536fd"
          onClick={handleMentorsMenuBtnClick}
          href="#"
          prop-events-value-onclick="handleMentorsMenuBtnClick"
        >
          <img
            alt=""
            className="user-menu-icon"
            loading="lazy"
            src="/images/users-round.svg"
          />
          <div className="user-menu-label">Mentors</div>
        </a>
      </div>
    </nav>
  );
};

export default Settingsmenudropdownbox;
