import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import useUpdateMentorSettings from './useUpdateMentorSettings';
import { useTriggers } from '../../navigation';
import useMentorUtils from '../../utils/useMentorUtils';

const DUMMY_CUSTOM_CSS = '/* CUSTOM_CSS */';

export default function useMentorEmbed(mentor) {
  const [mentorToEmbed, setMentorToEmbed] = useState(mentor);
  const { handleUpdateSettings } = useUpdateMentorSettings();
  const { _handleUpdateModalStatus } = useTriggers();
  const { updateLocalMentorSettings } = useMentorUtils();

  const [settings, setSettings] = useState({
    initial_message: '',
    suggested_message: '',
    is_context_aware: false,
    metadata: {
      primary_color: '#2467eb',
      secondary_color: '#000',
      safety_disclaimer: false,
    },
    allow_anonymous: true,
    custom_css: '',
  });

  const getExistingCss = (data) => {
    if (data?.custom_css && data.custom_css.trim() === DUMMY_CUSTOM_CSS) {
      return '';
    }
    return data?.custom_css ?? '';
  };

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (mentor) {
      const data = mentor.settings;
      const dataToOverride = {
        initial_message: data?.initial_message ?? '',
        suggested_message: data?.suggested_message ?? '',
        metadata: {
          ...settings.metadata,
          ...(data?.metadata ?? {}),
        },
        allow_anonymous: true,
        custom_css: getExistingCss(data),
      };
      setSettings(dataToOverride);
    }
  }, [mentor]);

  const handleSubmit = async () => {
    setSubmitting(true);
    const payload = {
      metadata: settings.metadata,
      allow_anonymous: true,
    };
    if (settings.initial_message) {
      payload['initial_message'] = settings.initial_message;
    }

    if (settings.suggested_message) {
      payload['suggested_message'] = settings.suggested_message;
    }

    if (settings.custom_css.trim()) {
      payload['custom_css'] = settings.custom_css;
    } else {
      payload['custom_css'] = DUMMY_CUSTOM_CSS;
    }

    if (settings.safety_disclaimer) {
      payload['safety_disclaimer'] = settings.safety_disclaimer;
    }

    const updatedSettings = await handleUpdateSettings(mentor, payload);
    updateLocalMentorSettings(mentor, updatedSettings);
    setMentorToEmbed({
      ...mentor,
      is_context_aware: settings.is_context_aware,
      safety_disclaimer: settings.safety_disclaimer,
      allow_anonymous: settings.allow_anonymous,
    });
    setSubmitting(false);
    _handleUpdateModalStatus('embed-mentor-modal', true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleMetadataChange = (event) => {
    const { name, value } = event.target;
    setSettings((prevState) => {
      return {
        ...prevState,
        metadata: { ...prevState.metadata, [name]: value },
      };
    });
  };

  const handleCheckChange = (event) => {
    const { name, checked } = event.target;
    setSettings((prevState) => {
      return { ...prevState, [name]: checked };
    });
  };

  const handleMetaDataCheckChange = (event) => {
    const { name, checked } = event.target;
    setSettings((prevState) => {
      return {
        ...prevState,
        metadata: {
          ...prevState.metadata,
          [name]: checked,
        },
      };
    });
  };

  const handleUpdatePreview = () => {
    const iframedSite = document.querySelector('#embed-mentor-preview');
    const message = JSON.stringify({
      welcomeMessage: settings?.initial_message,
      defaultPrompt: settings?.suggested_message,
      css: settings?.custom_css,
      allowAnonymous: true,
      internal_preview: true,
    });
    iframedSite.contentWindow.postMessage(message, '*');
  };

  useEffect(() => {
    handleUpdatePreview();
  }, [settings]);

  return {
    handleChange,
    handleCheckChange,
    handleMetadataChange,
    handleSubmit,
    mentorToEmbed,
    submitting,
    settings,
    handleMetaDataCheckChange,
  };
}
