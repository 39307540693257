import useFreeTrial from '../../hooks/freetrial/useFreeTrial';
import { useTriggers } from '../../hooks/navigation';

export default function FreeTrialModal() {
  const { handleUpgradeClick } = useFreeTrial(false);
  const { handleModalCloseClick } = useTriggers();
  return (
    <div
      react-component="EmbedMentorModalBox"
      className="modal not-admin-banner-modal"
    >
      <div className="w-layout-vflex modal-container-5 get-started-modal-container">
        <div className="w-layout-vflex get-started-main-content">
          <div className="w-layout-hflex flex-block-40">
            <div className="w-layout-hflex flex-block-39">
              <img
                loading="lazy"
                src="/images/star.svg"
                alt=""
                className="image-63"
              />
            </div>
            <h4 className="get-started-header">Upgrade Your Experience</h4>
          </div>
          <div className="get-started-rich-text w-richtext">
            <p>
              At the moment you’re only able to chat with the mentors for a
              limited time.
            </p>
            <p>
              If you’d also like to create your own mentors with your custom
              data, sources and behaviors, then please upgrade below.
            </p>
            <p>
              If you have any questions or need an enterprise license,{' '}
              <a href="mailto:support@iblai.zendesk.com">
                reach out to our team
              </a>{' '}
              and we’ll help you right away.
            </p>
            <p>Thank you for trusting us, and have a great day!</p>
          </div>
        </div>
        <div className="w-layout-hflex flex-block-38">
          <a
            href="#"
            onClick={handleUpgradeClick}
            className="banner-btn get-started-btn w-button"
          >
            Upgrade Now
          </a>
        </div>
        <div
          prop-events-value-onclick="handleModalCloseClick"
          prop-events-names="onClick"
          onClick={handleModalCloseClick}
          data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
          className="modal-close-wrapper"
        >
          <img
            src="/images/close_1close.png"
            loading="lazy"
            alt="Close Button Image"
          />
        </div>
      </div>
    </div>
  );
}
