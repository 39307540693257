import { createSlice } from '@reduxjs/toolkit';

export const authInitialState = {
  data: {
    tenants: [],
    user: null,
    tenant: null,
  },
  anonymous: false,
  freeTrialUsage: null,
  freeTrialExhausted: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    reset: () => authInitialState,
    authUpdated: (state, action) => {
      state.data = action.payload;
    },
    freeTrialUsageUpdated: (state, action) => {
      state.freeTrialUsage = action.payload;
    },
    freeTrialExhaustedUpdated: (state, action) => {
      state.freeTrialExhausted = action.payload;
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
