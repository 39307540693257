import React from 'react';
import { userIsOnTrial } from '../../utils/shared';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import './SubmitBtn.css';

const SubmitBtn = ({
  label = 'Save',
  saving = false,
  classname = '',
  //TODO uniformize classname into className
  className = '',
  loaderSize = 15,
  ...rest
}) => {
  return (
    <a
      className={`default-btn form-submit-btn invite-user-submit-btn w-button default-submit-btn ${classname} ${className} ${saving && 'disabled-btn'}`}
      href="#"
      {...rest}
    >
      <span>{label}</span>
      {saving && <IBLSpinLoader size={loaderSize} color={'#FFF'} />}
    </a>
  );
};

export default SubmitBtn;
