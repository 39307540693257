export default function PageLoader({ message }) {
  return (
    <div className="page-loader">
      <div className="page-loader-content">
        <div>
          <img
            className="page-loader-logo"
            src="/images/logo.gif"
            alt="Loading..."
          />
        </div>
        {message && <div className="loader-message">{message}</div>}
      </div>
    </div>
  );
}
