import { createSlice } from '@reduxjs/toolkit';

export const promptsInitialState = {
  data: {
    loadedPrompts: null,
    categories: null,
    prompts: null,
    activeCategory: 'All',
  },
  selectedPrompt: null,
};

export const promptSlice = createSlice({
  name: 'prompts',
  initialState: promptsInitialState,
  reducers: {
    reset: () => promptsInitialState,
    promptsUpdated: (state, action) => {
      state.data = action.payload;
    },
    selectedPromptUpdated: (state, action) => {
      state.selectedPrompt = action.payload;
    },
  },
});

export const promptActions = promptSlice.actions;
export default promptSlice.reducer;
