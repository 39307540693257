import { useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';

export default function useCheckout(data) {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const api = useApi();

  const updateFreeTrialStatus = async () => {
    const url = `/api/ai-account/orgs/${data?.tenant}/use-free-trial/`;
    const payload = {
      disable: true,
    };
    setLoading(true);
    const response = await api.post(url, payload);
    if (response?.data) {
      window.location.assign('/');
    } else {
      setError(true);
    }
    setLoading(false);
  };

  return { loading, error, updateFreeTrialStatus };
}
