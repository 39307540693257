import useApi from '../api/base/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { mentorActions } from '../../lib/redux/mentors/slice';

export default function useTools() {
  const auth = useSelector((state) => state.auth.data);
  const tools = useSelector((state) => state.mentors.tools);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const api = useApi();

  const loadTools = async () => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-tools/`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      dispatch(mentorActions.toolsUpdated(response.data));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!tools) {
      loadTools();
    }
  }, [tools]);

  return { loading };
}
