import React from 'react';
import useVoiceChat from '../../../hooks/chat-room/useVoiceChat';

export default function VoiceChat() {
  const { handleMicrophoneBtnClick, recording, processing } = useVoiceChat();
  return (
    <div
      className="user-input-actions-container greyish-bg-in-darkmode user-input-microphone-btn has-custom-tooltip"
      data-w-id="c4c241a8-c39d-44d4-4bf4-1cd95b8c7c6c"
      prop-events-names="onClick"
      prop-events-value-onclick="handleMicrophoneBtnClick"
      onClick={processing ? () => {} : handleMicrophoneBtnClick}
      style={{ backgroundColor: recording ? 'darkgray' : '' }}
    >
      <img
        alt=""
        className="image-6 whiten-in-dark-mode"
        loading="lazy"
        src="/images/microphone-svgrepo-com.svg"
      />
      <div className="tooltip tooltip-microphone">Use the microphone</div>
    </div>
  );
}
