import { useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';
import { toast } from 'react-toastify';
import { useTriggers } from '../navigation';
import useUpdateLocalLLMCredStatus from './useUpdateLocalLLMCredStatus';
import useLLMs from './useLLMs';

export default function useSetLLMCred() {
  const [key, setKey] = useState('');
  const [saving, setSaving] = useState(false);
  const auth = useSelector((state) => state.auth.data);
  const edit = useSelector((state) => state.mentors.edit);
  const selectedLLM = useSelector((state) => state.mentors.selectedLLM);
  const api = useApi();
  const { _handleUpdateModalStatus } = useTriggers();
  const { handleUpdateLLMCredItemStatus } = useUpdateLocalLLMCredStatus();
  const [fieldRequired, setFieldRequired] = useState(false);
  const { loadLLMs } = useLLMs();

  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleSaveKey = async (event) => {
    event.preventDefault();
    if (!key.trim()) {
      setFieldRequired(true);
      return;
    }
    setFieldRequired(false);
    const url = `/api/ai-account/orgs/${auth?.tenant?.key}/credential/`;
    const data = {
      name: edit?.llmProvider,
      value: {
        key,
      },
      platform: auth?.tenant?.key,
    };
    setSaving(true);
    let response = null;
    if (selectedLLM?.has_credentials) {
      response = await api.patch(url, data);
    } else {
      response = await api.post(url, data);
    }

    if (response?.data) {
      handleUpdateLLMCredItemStatus(edit?.llmProvider);
      toast.success(
        'Successfully saved key for this LLM. You can now start chatting'
      );
      setKey('');
      _handleUpdateModalStatus('llm-set-api-key-modal', false);
      await loadLLMs();
    } else {
      toast.error('Unable to save the LLM Key');
    }
    setSaving(false);
  };

  return { saving, handleKeyChange, handleSaveKey, key, fieldRequired };
}
