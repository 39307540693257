import React from 'react';

export default function MentorPagination({
  mentors,
  handlePreviousPage,
  handleNextPage,
  page,
}) {
  return (
    <>
      {mentors?.results?.length > 0 && (
        <div className="w-layout-hflex">
          <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
            <div
              className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode"
              style={{ display: 'none' }}
            >
              >
              <img
                alt=""
                className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                loading="lazy"
                src="/images/chevron-first.svg"
              />
            </div>
            <div
              onClick={
                mentors?.previous
                  ? () => handlePreviousPage(mentors?.page)
                  : () => {}
              }
              className={`w-layout-hflex table-pagination-btn greyish-bg-in-darkmode ${mentors?.previous ? '' : 'table-pagination-btn-inactive'}`}
            >
              <img
                alt=""
                className={`table-pagination-btn-icon ${mentors?.previous ? '' : 'table-pagination-btn-icon-disabled'}`}
                loading="lazy"
                src="/images/chevron-left-2.svg"
              />
            </div>
            <div className="w-layout-hflex table-pagination-btn table-pagination-btn-active">
              <h5 className="table-pagination-page-number primary-color-in-dark-mode">
                {mentors?.page ?? 1}
              </h5>
            </div>

            <div
              onClick={
                mentors?.next ? () => handleNextPage(mentors?.page) : () => {}
              }
              className={`w-layout-hflex table-pagination-btn greyish-bg-in-darkmode ${mentors?.next ? '' : 'table-pagination-btn-inactive'}`}
            >
              <img
                alt=""
                className={`table-pagination-btn-icon ${mentors?.next ? '' : 'table-pagination-btn-icon-disabled'}`}
                loading="lazy"
                src="/images/chevron-right.svg"
              />
            </div>
            <div
              className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode"
              style={{ display: 'none' }}
            >
              <img
                alt=""
                className="table-pagination-btn-icon"
                loading="lazy"
                src="/images/chevron-last-1.svg"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
