/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
      { loading: Promise.resolve("Webflow.push(function(){$(\"input\").length&&$(\"input\").each(t=>{var u=$(this).attr(\"custom-value\");u&&$(this).val(u)})});"), isAsync: false },
      ]

let Controller

class ResetPasswordView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ResetPasswordController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ResetPasswordView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '6594752b8f2ebca11a110624'
    htmlEl.dataset['wfSite'] = '650305782bc61751e5765211'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function().call(window, script)
        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
 
    const proxies = ResetPasswordView.Controller !== ResetPasswordView ? transformProxies(this.props.children) : {

    }

    return (
<span>
<style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/webflow.css);
          @import url(/css/ibl-aimentorpro.webflow.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }
        ` }} />
<span className="af-view">
<div className="body-3 reset-password-page">
<div className="w-layout-vflex auth-page-container">
<div className="w-layout-vflex auth-page-block"><img alt="" className="auth-logo-img" loading="lazy" src="/images/ibl-logo-animated.gif"/>
<div className="w-layout-vflex auth-page-main-content">
<h2 className="auth-page-title smaller">Reset your password</h2>
<div className="auth-title-desc">Enter your new password below.</div>
<div className="auth-form-block w-form">
<form className="auth-form" data-name="Email Form" data-wf-element-id="7728b33c-190c-6cc0-ea53-62f3fba1f550" data-wf-page-id="6594752b8f2ebca11a110624" id="email-form" method="get" name="email-form">
<div className="w-layout-vflex auth-input-group visible"><input className="auth-input password-field password w-input" data-name="Name 2" id="password" maxLength={256} name="name-2" placeholder="Password" required="" type="password"/>
<div className="text-error hidden">Minimum 8 characters</div>
<div className="w-layout-hflex password-eye-block"><img alt="" className="password-eye-icon hidden" loading="lazy" src="/images/eye-off.svg"/><img alt="" className="password-eye-icon" loading="lazy" src="/images/eye-1.svg"/></div>
</div>
<div className="w-layout-vflex auth-input-group visible"><input className="auth-input password-field password-confirm w-input" data-name="Name 2" id="password-confirm" maxLength={256} name="name-2" placeholder="Confirm password" required="" type="password"/>
<div className="text-error hidden">Minimum 8 characters</div>
<div className="w-layout-hflex password-eye-block"><img alt="" className="password-eye-icon hidden" loading="lazy" src="/images/eye-off.svg"/><img alt="" className="password-eye-icon" loading="lazy" src="/images/eye-1.svg"/></div>
</div>
<div className="w-layout-hflex auth-submit-btn">
<div className="auth-submit-btn-label">Continue</div>
<div className="btn-spinner-block hidden w-embed">
<style dangerouslySetInnerHTML={{__html: `
.af-view .lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.af-view .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top:10px;
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.af-view .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.af-view .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.af-view .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
 ` }} />
<div className="lds-ring">
<div></div>
<div></div>
<div></div>
<div></div>
</div>
</div>
</div><input className="submit-button-2 w-button" data-wait="Please wait..." defaultValue="Submit" type="submit"/>
</form>
<div className="w-form-done">
<div>Thank you! Your submission has been received!</div>
</div>
<div className="w-form-fail">
<div>Oops! Something went wrong while submitting the form.</div>
</div>
</div>
<div className="w-layout-hflex auth-redirect-block"></div>
</div>
<div className="w-layout-hflex auth-footer">
<a className="link-block w-inline-block" href="#">
<div className="auth-footer-links">Terms of Use</div>
</a>
<div className="div-block-35"></div>
<a className="link-block w-inline-block" href="#">
<div className="auth-footer-links">Privacy Policy</div>
</a>
</div>
</div>
</div>
<div className="notifications-block">
<div className="w-layout-hflex notification-element error">
<div className="w-layout-hflex notification-header-block"><img alt="" className="notification-header-block-icon" loading="lazy" src="/images/close-1_1close-1.png"/>
<div className="notification-header-title">Something went wrong</div>
</div>
<div className="notification-body">
<p className="notification-body-parag">Your Email adress is incorrect.</p>
</div>
<div className="w-layout-hflex notification-close"><img alt="" className="notification-close-icon" loading="lazy" src="/images/x.svg"/></div>
</div>
<div className="w-layout-hflex notification-element info">
<div className="w-layout-hflex notification-header-block"><img alt="" className="notification-header-block-icon" loading="lazy" src="/images/information_1information.png"/>
<div className="notification-header-title">Password reset effective</div>
</div>
<div className="notification-body">
<p className="notification-body-parag">Platform initializing, you'll be redirected shortly...</p>
</div>
<div className="w-layout-hflex notification-close"><img alt="" className="notification-close-icon" loading="lazy" src="/images/x.svg"/></div>
</div>
<div className="w-layout-hflex notification-element success">
<div className="w-layout-hflex notification-header-block"><img alt="" className="notification-header-block-icon" loading="lazy" src="/images/check-1_1check-1.png"/>
<div className="notification-header-title">Password reset successful</div>
</div>
<div className="notification-body">
<p className="notification-body-parag">Platform initializing, you'll be redirected shortly...</p>
</div>
<div className="w-layout-hflex notification-close"><img alt="" className="notification-close-icon" loading="lazy" src="/images/x.svg"/></div>
</div>
</div>
</div>
</span>
</span>
)}
}

export default ResetPasswordView

/* eslint-enable */