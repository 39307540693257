import { useState } from 'react';
import useAIResponseRating from '../../../hooks/chat-room/useAIResponseRating';

export default function AIResponseRating({ msg, userMsg }) {
  const { msgRating, handleRateResponse } = useAIResponseRating(msg, userMsg);
  return (
    <div
      className="w-layout-hflex learn-more-container all-whiten-in-dark-mode"
      style={{ display: 'flex' }}
    >
      <div className="w-layout-hflex ai-response-rating-block all-whiten-in-dark-mode">
        <div
          onClick={() => {
            handleRateResponse(1);
          }}
          data-w-id="c3b0ec91-0978-8090-19f5-759716404284"
          className={`w-layout-hflex ai-response-rating-thumb greyish-bg-in-darkmode ${msgRating?.rating === 1 ? 'active' : ''}`}
        >
          <img
            src="/images/thumbs-up.svg"
            loading="lazy"
            alt=""
            className="ai-response-rating-thumb-icon inactive"
          />
          <img
            src="/images/thumbs-up-1.svg"
            loading="lazy"
            alt=""
            className="ai-response-rating-thumb-icon active"
          />
        </div>
        <div
          onClick={() => {
            handleRateResponse(-1);
          }}
          data-w-id="c3b0ec91-0978-8090-19f5-759716404288"
          className={`w-layout-hflex ai-response-rating-thumb greyish-bg-in-darkmode ${msgRating?.rating === -1 ? 'active' : ''}`}
        >
          <img
            src="/images/thumbs-down.svg"
            loading="lazy"
            alt=""
            className="ai-response-rating-thumb-icon inactive"
          />
          <img
            src="/images/thumbs-down-1.svg"
            loading="lazy"
            alt=""
            className="ai-response-rating-thumb-icon active"
          />
        </div>
      </div>
    </div>
  );
}
