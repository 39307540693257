import useApi from '../base/useApi';

export default function useMentorDetails() {
  const api = useApi();
  const loadMentorDetails = async (tenant, slug) => {
    const url = `/api/search/ai-search/?tenant="${tenant}"&facet=["${slug}"]`;
    const response = await api.get(url);
    if (response?.data) {
      return response.data;
    }
  };

  return { loadMentorDetails };
}
