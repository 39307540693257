import React from 'react';
import './Llmsmodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import LLMs from '../../modules/llms';

const Llmsmodalbox = (props) => {
  const { handleModalCloseClick } = useTriggers();

  return (
    <div className="modal llm-modal">
      <div className="w-layout-vflex modal-container prompt-gallery-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">LLMs</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            + Add New Prompt
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode llm-modal-body">
          <div className="w-layout-vflex llm-desc-block">
            <div className="llm-header-desc">
              Pre-trained multi task models than can further be tuned or
              customized for specific tasks.
            </div>
          </div>

          <LLMs />
        </div>
      </div>
    </div>
  );
};

export default Llmsmodalbox;
