export default function useMentorSlug(mentor) {
  if (mentor?.slug?.length < 8) {
    return mentor.slug;
  }
  const mentorParts = mentor?.slug?.split('-');
  if (mentorParts?.length > 3) {
    return mentorParts[0];
  }
  return mentor?.slug;
}
