import React from 'react';
import './Promptgallerycategory.css';
import usePromptActiveCategory from '../../hooks/api/prompts/usePromptActiveCategory';

const Promptgallerycategory = (props) => {
  const { handlePromptGalleryCategoryClick, active } = usePromptActiveCategory(
    props.title
  );
  return (
    <div
      className={`w-layout-hflex prompt-category-element ${active ? 'active-category-element' : ''} `}
      map=""
      map-value="category"
      prop-events-names="onClick"
      onClick={handlePromptGalleryCategoryClick}
      prop-events-value-onclick="handlePromptGalleryCategoryClick"
    >
      <div className="prompt-category-label">{props.title}</div>
    </div>
  );
};

export default Promptgallerycategory;
