import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useApi from '../api/base/useApi';

export default function useAIResponseRating(msg, userMsg) {
  const auth = useSelector((state) => state.auth.data);
  const mentor = useSelector((state) => state.mentors.mentor);
  const sessionId = useSelector((state) => state.chat.sessionId);

  const [msgRating, setMsgReating] = useState(null);

  const api = useApi();

  const handleRateResponse = async (rating) => {
    const url = `/api/ai-mentor/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/mentor-feedback/create/`;
    const payload = {
      rating,
      ai_response: msg.rawContent,
      user_text: userMsg?.rawContent ?? userMsg?.content,
      session: sessionId,
      mentor: mentor?.name,
      username: auth?.user?.user_nicename,
      reason: rating === 1 ? 'Good response' : 'Bad response',
      additional_feedback: rating === 1 ? 'Good response' : 'Bad response',
    };
    const response = await api.post(url, payload);
    if (response?.data) {
      setMsgReating(response?.data);
    }
  };

  useEffect(() => {
    setMsgReating(null);
  }, [msg]);

  return { handleRateResponse, msgRating };
}
