import React from 'react';
import LLMs from '../../llms';

export default function MentorLLMs() {
  return (
    <div
      className="w-layout-vflex edit-mentor-llm-tab"
      style={{ alignItems: 'center' }}
    >
      <LLMs showSelected={true} />
    </div>
  );
}
