/* eslint-disable */

import React from 'react';
import { createScope, map, transformProxies } from './helpers';

const scripts = [
  {
    loading: Promise.resolve(
      '/[?&]e=1(&|$)/.test(document.location.search)&&(document.querySelector(".w-password-page.w-form-fail").style.display="block");'
    ),
    isAsync: false,
  },
  {
    loading: Promise.resolve(
      'Webflow.push(function(){$("input").length&&$("input").each(t=>{var u=$(this).attr("custom-value");u&&$(this).val(u)})});'
    ),
    isAsync: false,
  },
];

let Controller;

class UnauthorizedView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/UnauthorizedController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = UnauthorizedView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '650305782bc61751e576521b';
    htmlEl.dataset['wfSite'] = '650305782bc61751e5765211';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function().call(window, script);
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      UnauthorizedView.Controller !== UnauthorizedView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/webflow.css);
          @import url(/css/ibl-aimentorpro.webflow.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div className="utility-page-wrap">
              <div className="utility-page-content w-password-page w-form">
                <form
                  action="/.wf_auth"
                  className="utility-page-form w-password-page"
                  data-name="Email Form"
                  data-wf-element-id="64dc4947fec90a680eca1b5300000000000c"
                  data-wf-page-id="650305782bc61751e576521b"
                  id="email-form"
                  method="post"
                  name="email-form"
                >
                  <img
                    alt=""
                    src="https://d3e54v103j8qbb.cloudfront.net/static/utility-lock.ae54711958.svg"
                  />
                  <h2>Protected Page</h2>
                  <label className="w-password-page" htmlFor="pass">
                    Password
                  </label>
                  <input
                    autofocus="true"
                    className="w-password-page w-input"
                    data-name="field"
                    id="pass"
                    maxLength={256}
                    name="pass"
                    placeholder="Enter your password"
                    type="password"
                  />
                  <input
                    className="w-password-page w-button"
                    data-wait="Please wait..."
                    defaultValue="Submit"
                    type="submit"
                  />
                  <div className="w-password-page w-form-fail">
                    <div>Incorrect password. Please try again.</div>
                  </div>
                  <div
                    className="w-password-page w-embed w-script"
                    style={{ display: 'none' }}
                  >
                    <input
                      defaultValue="&lt;%WF_FORM_VALUE_PATH%&gt;"
                      name="path"
                      type="hidden"
                    />
                    <input
                      defaultValue="&lt;%WF_FORM_VALUE_PAGE%&gt;"
                      name="page"
                      type="hidden"
                    />
                  </div>
                  <div
                    className="w-password-page w-embed w-script"
                    style={{ display: 'none' }}
                  ></div>
                </form>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  }
}

export default UnauthorizedView;

/* eslint-enable */
