import { toast } from 'react-toastify';
import useAnonymousMode from './useAnonymousMode';
import useApi from '../api/base/useApi';
import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useEffect, useState } from 'react';
import { authActions } from '../../lib/redux/auth/slice';
import { useParams } from 'react-router-dom';
import useMentorDetails from '../api/mentors/useMentorDetails';

export default function useAnonymousMentor() {
  const anonymous = useAnonymousMode();
  const urlParams = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const mentor = pathParams?.mentor || urlParams.get('mentor');
  const tenant = pathParams?.platform || urlParams.get('tenant');
  const dispatch = useDispatch();
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const auth = useSelector((state) => state.auth.data);

  const { loadMentorDetails } = useMentorDetails();

  if (anonymous && !mentor) {
    toast.error('No mentor specified');
  }

  const loadExtraBodyClasses = () => {
    if (urlParams.get('extra-body-classes')) {
      let extraBodyClasses = urlParams.get('extra-body-classes');
      extraBodyClasses = extraBodyClasses.split(',');
      extraBodyClasses.forEach((className) => {
        document?.body?.classList?.add(className);
      });
    }
  };

  const loadMentorPublicSettings = async () => {
    const url = `/api/ai-mentor/orgs/${tenant}/users/anonymous/mentors/${mentor}/public-settings/`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      const settings = response.data;
      if (
        !settings?.allow_anonymous &&
        urlParams.get('internalPreview') !== 'true'
      ) {
        toast.error("Can't use this mentor anonymously");
        return;
      }
      const mentorData = {
        name: settings.mentor,
        slug: settings.mentor_slug,
        unique_id: settings.mentor_unique_id,
        suggested_prompts: settings?.suggested_prompts,
        settings,
        proactive_prompt: settings?.proactive_prompt,
      };
      dispatch(mentorActions.currentMentorUpdated(mentorData));
      dispatch(authActions.authUpdated({ ...auth, tenant: { key: tenant } }));

      if (urlParams.get('internalPreview') === 'true') {
        localStorage.setItem('previewMentorData', JSON.stringify(mentorData));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadExtraBodyClasses();
  }, []);

  useEffect(() => {
    if (anonymous && tenant && mentor) {
      loadMentorPublicSettings();
    }
  }, []);

  return { loading };
}
