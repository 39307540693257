import React from 'react';

const ChromeExtSSOLoginComplete = () => {
  return (
    <div className="w-layout-hflex-error-ui error-page-main-container">
      <div className="w-layout-hflex-error-ui error-page-layout">
        <div className="w-layout-hflex-error-ui error-page-right-side">
          <h1 className="error-page-secondary-heading">Login successful!</h1>
          <div className="error-page-description">
            Your login request has been completed. You can now open your
            extension.
          </div>
          <div className="error-page-description">
            This tab will be closed...
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChromeExtSSOLoginComplete;
