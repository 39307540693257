/* eslint-disable */

import React from 'react'
import { createScope, map, transformProxies } from './helpers'

const scripts = [
      { loading: Promise.resolve("Webflow.push(function(){$(\"input\").length&&$(\"input\").each(t=>{var u=$(this).attr(\"custom-value\");u&&$(this).val(u)})});"), isAsync: false },
]

let Controller

class ShareView extends React.Component {
  static get Controller() {
    if (Controller) return Controller

    try {
      Controller = require('../controllers/ShareController')
      Controller = Controller.default || Controller

      return Controller
    }
    catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = ShareView

        return Controller
      }

      throw e
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html')
    htmlEl.dataset['wfPage'] = '65b3c48aa59c165f99466acb'
    htmlEl.dataset['wfSite'] = '650305782bc61751e5765211'

    scripts.concat(null).reduce((active, next) => Promise.resolve(active).then((active) => {
      const loading = active.loading.then((script) => {
        new Function().call(window, script)
        return next
      })

      return active.isAsync ? next : loading
    }))
  }

  render() {
 
    const proxies = ShareView.Controller !== ShareView ? transformProxies(this.props.children) : {

    }

    return (
<span>
<style dangerouslySetInnerHTML={{ __html: `
          @import url(/css/webflow.css);
          @import url(/css/ibl-aimentorpro.webflow.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }
        ` }} />
<span className="af-view">
<div className="body">
<div className="w-layout-hflex share-main-content">
<div className="w-layout-hflex flex-block-85">
<div className="w-layout-vflex share-header">
<h3 className="heading-24">Public Kissing: Legal Considerations</h3>
<div className="text-block-55">July 7, 2023</div>
</div>
<div className="share-header-seperator"></div>
<div className="chat-export-container-top-block share-chat-export-container">
<div className="w-layout-hflex chat-export-header share-export-header">
<div className="chat-export-header-block share-export-header-block">
<div className="chat-export-icon-container"><img alt="" className="image-26" loading="lazy" src="/images/user.svg"/></div>
<div className="w-layout-vflex share-export-header-main-block">
<h5 className="heading-25">Anonymous</h5>
<p className="paragraph-2 share-paragraph">In law what harm could i suffer when i see two people kissing in public</p>
</div>
</div>
</div>
<div className="w-layout-hflex chat-export-header chat-export-body share-export-body share-export-header">
<div className="chat-export-header-block">
<div className="chat-export-icon-container"><img alt="" className="image-26" loading="lazy" src="/images/bot.svg"/></div>
<div className="w-layout-vflex share-export-header-main-block">
<h5 className="heading-25">AI Mentor Pro</h5>
<p className="paragraph-2 share-paragraph">As an AI language model, I can provide you with some general information, but please keep in mind that laws vary across jurisdictions, and I am not a lawyer. If you have concerns about a specific jurisdiction, it's always best to consult with a legal professional who can provide advice based on the laws applicable to your situation.<br/><br/>As an AI language model, I can provide you with some general information, but please keep in mind that laws vary across jurisdictions, and I am not a lawyer. If you have concerns about a specific jurisdiction, it's always best to consult with a legal professional who can provide advice based on the laws applicable to your situation.<br/><br/>As an AI language model, I can provide you with some general information, but please keep in mind that laws vary across jurisdictions, and I am not a lawyer. If you have concerns about a specific jurisdiction, it's always best to consult with a legal professional who can provide advice based on the laws applicable to your situation.</p>
</div>
</div>
</div>
</div>
<div className="w-layout-hflex flex-block-86">
<a className="button w-button" href="https://ibl-aimentorpro.webflow.io/">Get Started With AI Mentor Pro</a>
</div>
</div>
</div>
</div>
</span>
</span>
)}
}

export default ShareView

/* eslint-enable */