import PageLoader from '../loader/page-loader';
import { useEffect } from 'react';
import useCheckout from '../../hooks/freetrial/useCheckout';

export default function CheckoutSuccess({ appData }) {
  const { error, updateFreeTrialStatus } = useCheckout(appData);
  useEffect(() => {
    // updateFreeTrialStatus();
    setTimeout(() => {
      window.location.assign('/');
    }, 1000);
  }, []);

  return (
    <>
      <PageLoader message="Your payment has been completed. We are finalizing..." />
    </>
  );
}
