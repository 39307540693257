import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  isRouteErrorResponse,
} from 'react-router-dom';
import { useState } from 'react';
import {
  isJSON,
  Logout,
  SsoLogin,
  AppDataContext,
  GLOBAL_COLORS,
  getUserDataFromCookie,
  getUserName,
  api,
  sentryCreateBrowserRouter,
} from '@iblai/ibl-web-react-common';
import { createGlobalStyle } from 'styled-components';
import '@iblai/ibl-web-react-common/index.css';

import IndexView from './views/IndexView';

import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';

import { Provider as ReduxProvider } from 'react-redux';
import './App.css';
import { store } from './lib/redux/store';
import LoggedOut from './modules/logged-out';
import { redirectToAuth } from './utils/auth';
import Login from './modules/login';
import useAnonymousMode from './hooks/iframe/useAnonymousMode';
import ChromeExtSSOLogin from './modules/chrome-extension/chrome-ext-sso-login';
import ChromeExtSSOLoginComplete from './modules/chrome-extension/chrome-ext-sso-login-complete';
import { mentorIsIframe } from './utils/shared';
import CheckoutSuccess from './modules/checkout/success';
import CheckoutCancel from './modules/checkout/cancel';
import './i18n';
import Platform from './modules/platform';
import NoMentors from './modules/no-mentors';

const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 />;
    }
  }
};

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
    userInContext: '',
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData);
  const anomymous = useAnonymousMode();
  const userDataFromCookie = getUserDataFromCookie();

  const authCheckFn = (isAdmin, isLoggedIn, appData) => {
    const currentTenantData = isJSON(appData.current_tenant)
      ? JSON.parse(appData.current_tenant)
      : appData?.current_tenant;
    return () => {
      if (anomymous || mentorIsIframe() || !isLoggedIn) {
        return [];
      }

      if (isAdmin && currentTenantData && !currentTenantData?.is_admin) {
        throw new Response('Not Admin', { status: 403 });
      }
      if (isAdmin && !currentTenantData) {
        return redirectToAuth();
      }

      if (
        (isLoggedIn && (!appData?.axd_token || !appData?.dm_token)) ||
        !userDataFromCookie ||
        userDataFromCookie?.user_id !== appData.userData?.user_id
      ) {
        return redirectToAuth();
      }

      return new Promise((resolve) => {
        if (!Object.keys(appData.userMetaData).length) {
          api.ibledxusers.getUsersManageMetadata(
            { username: getUserName() },
            function (data) {
              setAppData({
                ...appData,
                userMetaData: { ...data },
              });
              resolve([]);
            },
            () => {
              resolve([]);
            }
          );
        } else {
          resolve([]);
        }
      });
    };
  };

  return (
    <>
      <GlobalStyles />
      <EmbedCustomStyles />
      <ReduxProvider store={store}>
        <AppDataContext.Provider
          value={{ appData: { ...appData }, setAppData }}
        >
          <RouterProvider
            router={sentryCreateBrowserRouter([
              {
                path: '/',
                errorElement: <ErrorPage />,
                children: [
                  {
                    path: '/',
                    element: <IndexView />,
                    loader: authCheckFn(false, true, appData),
                  },
                  {
                    path: '/no-mentors',
                    element: <NoMentors />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: '/index.html',
                    element: <IndexView />,
                    loader: authCheckFn(false, true, appData),
                  },

                  {
                    path: '/share/chat/:sessionId',
                    element: <IndexView />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'sso-login',
                    element: <SsoLogin />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'platform/:platform/:mentor',
                    element: <Platform />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'chrome-ext-sso-login',
                    element: <ChromeExtSSOLogin />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'chrome-ext-login-complete',
                    element: <ChromeExtSSOLoginComplete />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'payment/success',
                    element: <CheckoutSuccess appData={appData} />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'payment/cancel',
                    element: <CheckoutCancel />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'logged-out',
                    element: <LoggedOut />,
                    loader: authCheckFn(false, false, appData),
                  },
                  {
                    path: 'logout',
                    element: <Logout />,
                    loader: authCheckFn(false, false, appData),
                  },
                ],
              },
            ])}
          />
        </AppDataContext.Provider>
      </ReduxProvider>
    </>
  );
}

const GlobalStyles = createGlobalStyle`
  html {
    --grey: ${GLOBAL_COLORS.grey};
    --font: ${GLOBAL_COLORS.font};
    --accent: ${GLOBAL_COLORS.primary};
    --accenthover: ${GLOBAL_COLORS.secondary};
    --accent-2: ${GLOBAL_COLORS.tertiary};
    --gainsboro: ${GLOBAL_COLORS.grey_secondary};
    --white-smoke: ${GLOBAL_COLORS.white_smoke};
    --white: white;
    --black: black;
    --3: white;
  }
`;

const EmbedCustomStyles =
  new URLSearchParams(window.location.search).get('mode') === 'anonymous' &&
  mentorIsIframe()
    ? createGlobalStyle`
    .user-prompt-text{
        border-radius: 8px 8px 0 8px!important;
        min-height: 20px!important;
        padding: 6px 9px!important;
        font-size: 13px!important;
        line-height: 20px!important;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .1)!important;
    }
    .user-prompt-text-block{
        max-width: 280px!important;
    }
    .learn-more-container{
        grid-row-gap: 3px!important;
    }
    .ai-response-container{
        border-radius: 15px 15px 15px 0px!important;
        padding-top: 7px!important;
        padding-bottom: 5px!important;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1)!important;
    }
    .ai-gif-container{
        padding-top: 5px!important;
    }
    .learn-more-block{
        border-top: .3px solid #a7a7a736!important;
        margin-top: 5px!important;
        padding: 5px 20px 0!important;
    }
    .response-action-icon{
        width: 14px!important;
    }
    .response-action-label{
        font-size: 12px!important;
    }
    .logo-container{
        grid-column-gap: 10px!important;
    }
    .text-block-35.suggested-prompt-label{
        font-size: 12px!important;
    }
    .user-prompt-input-field.black-bg-dark-mode.whiten-border-in-dark-mode.whiten-in-dark-mode{
        font-size: 14px!important;
    }
    .logo-container img{
        width: 30px!important;
    }
    .default-suggestion-prompts-block, .ai-default-msg{
        font-size: 14px!important;
    }
    .ai-response-text-container{
        grid-row-gap: 5px!important;
    }
`
    : () => {
        return <></>;
      };

export default App;
