import React from 'react';
import './Promptbox.css';
import PromptItem from '../../modules/prompts/prompt-item';

const Promptbox = (props) => {
  const prompt = props.prompt;
  return <PromptItem prompt={prompt} />;
};

export default Promptbox;
