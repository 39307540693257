import React from 'react';

export default function Pagination({
  data,
  handlePreviousPage,
  handleNextPage,
}) {
  return (
    <div className="w-layout-hflex">
      <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
        <div
          className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode"
          style={{ display: 'none' }}
        >
          >
          <img
            alt=""
            className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
            loading="lazy"
            src="/images/chevron-first.svg"
          />
        </div>
        <div
          onClick={
            data?.previous ? () => handlePreviousPage(data?.page) : () => {}
          }
          className={`w-layout-hflex table-pagination-btn greyish-bg-in-darkmode ${data?.previous ? '' : 'table-pagination-btn-inactive'}`}
        >
          <img
            alt=""
            className={`table-pagination-btn-icon ${data?.previous ? '' : 'table-pagination-btn-icon-disabled'}`}
            loading="lazy"
            src="/images/chevron-left-2.svg"
          />
        </div>
        <div
          className="w-layout-hflex table-pagination-btn table-pagination-btn-active"
          style={{ display: 'none' }}
        >
          <h5 className="table-pagination-page-number primary-color-in-dark-mode">
            {data?.page ?? 1}
          </h5>
        </div>

        <div
          onClick={data?.next ? () => handleNextPage(data?.page) : () => {}}
          className={`w-layout-hflex table-pagination-btn greyish-bg-in-darkmode ${data?.next ? '' : 'table-pagination-btn-inactive'}`}
        >
          <img
            alt=""
            className={`table-pagination-btn-icon ${data?.next ? '' : 'table-pagination-btn-icon-disabled'}`}
            loading="lazy"
            src="/images/chevron-right.svg"
          />
        </div>
        <div
          className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode"
          style={{ display: 'none' }}
        >
          <img
            alt=""
            className="table-pagination-btn-icon"
            loading="lazy"
            src="/images/chevron-last-1.svg"
          />
        </div>
      </div>
    </div>
  );
}
