import React from 'react';
import Helpmenudropdownbox from '../../components/Helpmenudropdownbox/Helpmenudropdownbox';
import { useHeader } from '../../hooks/header/useHeader';
import ThemeSwitcher from './theme-switcher';
import { useMenus, useTriggers } from '../../hooks/navigation';
import { useSelector } from 'react-redux';
import $ from 'jquery';
import { useDebounce } from '../../hooks/useDebounce';

const SidebarBottomBlock = () => {
  const { menus } = useSelector((state) => state.navigation.data);
  const { refs } = useMenus();
  const { handleHelpMenuBtnClick } = useTriggers();
  const { HELP_THREE_DOT_MENU_ENABLED } = useHeader();

  const handleJQueryScripts = () => {
    $(document).on('click', '.help-action-btn', function () {
      $(this)
        .closest('.header-help-dropdown')
        .find('.w-dropdown-list')
        .fadeIn('fast')
        .css({ display: 'flex' });
    });

    $(document).on('click', function (e) {
      if (
        $(
          '.header-help-dropdown-container.bottom-side-positionned[style*="display: flex"]'
        ) &&
        !$(e.target).closest(
          '.header-help-dropdown-container.bottom-side-positionned .help-user-menu-dropdown-block'
        ).length
      ) {
        $('.header-help-dropdown-container.bottom-side-positionned').hide();
      }
    });
  };

  useDebounce(handleJQueryScripts, 150, []);

  return (
    <div className="w-layout-vflex left-sidebar-bottom-block all-whiten-in-dark-mode">
      {HELP_THREE_DOT_MENU_ENABLED && (
        <div className="w-layout-hflex header-action-btn-block info-header-action-btn-block bottom-side-positionned">
          <div
            data-hover="false"
            data-delay={0}
            className="header-help-dropdown w-dropdown"
          >
            <div
              title="Action menu"
              className="header-action-btn all-whiten-in-dark-mode greyish-bg-in-darkmode left-sidebar-bottom-btn w-dropdown-toggle has-custom-tooltip help-action-btn"
            >
              <img
                src="/images/more-vertical.svg"
                loading="lazy"
                alt="View All Menu Items By Clicking"
                className="ai-expert-header-action-icon"
              />
              <div className="left-sidebar-bottom-btn-label">Support Hub</div>
              <div className="tooltip tooltip-left-sidebar-bottom-btn support-zone">
                Support Hub
              </div>
            </div>
            <Helpmenudropdownbox
              style={{ display: 'none' }}
              leftSideBarBottomPositionned={true}
            />
          </div>
        </div>
      )}
      <ThemeSwitcher />
    </div>
  );
};

export default SidebarBottomBlock;
