import { mentorActions } from '../../lib/redux/mentors/slice';
import { useDispatch, useSelector } from 'react-redux';

export default function useUpdateLocalLLMCredStatus() {
  const dispatch = useDispatch();
  const mentors = useSelector((state) => state.mentors);
  const handleUpdateLLMCredItemStatus = (llmProviderName) => {
    const updatedLLMs = mentors.llms?.map((item) => {
      if (item.name === llmProviderName) {
        return { ...item, validKey: true };
      }
      return item;
    });
    dispatch(mentorActions.llmsUpdated(updatedLLMs));
  };

  return { handleUpdateLLMCredItemStatus };
}
