import React from 'react';

const FormErrorHelper = ({
  errorText = 'This field is required',
  className = '',
  focused = false,
  ...rest
}) => {
  return (
    <div
      className={`form-input-error-helper ${!focused && 'hidden'} ${className}`}
      {...rest}
    >
      {errorText}
    </div>
  );
};

export default FormErrorHelper;
