import React from 'react';
import './NoRecordFound.css'

const NoRecordFound = ({text="No record found"}) => {
  return (
    <div className={"no-record-found"}>
      {text}
    </div>
  );
};

export default NoRecordFound;