import Userpromptbox from '../components/Userpromptbox/Userpromptbox';
import Helpmenudropdownbox from '../components/Helpmenudropdownbox/Helpmenudropdownbox';
import Settingsmenudropdownbox from '../components/Settingsmenudropdownbox/Settingsmenudropdownbox';
import Header from '../components/Header/Header';
import Usermenudropdownbox from '../components/Usermenudropdownbox/Usermenudropdownbox';
import Tenantmenudropdownbox from '../components/Tenantmenudropdownbox/Tenantmenudropdownbox';
import Mobilesettingsmenudropdownbox from '../components/Mobilesettingsmenudropdownbox/Mobilesettingsmenudropdownbox'; /* eslint-disable */

import React from 'react';
import { createScope, map, transformProxies } from './helpers';

const scripts = [
  {
    loading: Promise.resolve(
      'Webflow.push(function(){$("input").length&&$("input").each(t=>{var u=$(this).attr("custom-value");u&&$(this).val(u)})});'
    ),
    isAsync: false,
  },
];

let Controller;

class HomeCopyView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require('../controllers/HomeCopyController');
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == 'MODULE_NOT_FOUND') {
        Controller = HomeCopyView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector('html');
    htmlEl.dataset['wfPage'] = '650305782bc61751e576521e';
    htmlEl.dataset['wfSite'] = '650305782bc61751e5765211';

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function().call(window, script);
          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      HomeCopyView.Controller !== HomeCopyView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/webflow.css);
          @import url(/css/ibl-aimentorpro.webflow.css);
          @import url(/custom-assets/custom.css);


          @supports (-webkit-overflow-scrolling: touch) {
             input:focus, textarea:focus {
               font-size: 16px;
             }
          }
        `,
          }}
        />
        <span className="af-view">
          <div className="body">
            <Header></Header>
            <section className="main-content">
              <div className="body-left-side all-whiten-in-dark-mode">
                <a
                  className="menu-link greyish-bg-in-darkmode w-inline-block"
                  href="#"
                >
                  <img
                    alt=""
                    className="image-2"
                    id="new-course-icon"
                    loading="lazy"
                    src="/images/reload-svgrepo-com.svg"
                  />
                  <div className="text-block-3" id="new-course-label">
                    New Chat
                  </div>
                </a>
                <a
                  className="menu-link greyish-bg-in-darkmode w-inline-block"
                  data-w-id="44bc8451-176f-9271-666b-309107ada76a"
                  href="#"
                >
                  <img
                    alt=""
                    className="image-2"
                    id="new-course-icon"
                    loading="lazy"
                    src="/images/history.svg"
                  />
                  <div className="text-block-3" id="new-course-label">
                    History
                  </div>
                </a>
                <a
                  className="menu-link greyish-bg-in-darkmode w-inline-block"
                  data-w-id="fcbbf042-c73a-3ac8-e36f-c786bad38abe"
                  href="#"
                >
                  <img
                    alt=""
                    className="image-2"
                    id="new-course-icon"
                    loading="lazy"
                    src="/images/help-circle.svg"
                  />
                  <div className="text-block-3" id="new-course-label">
                    Prompts
                  </div>
                </a>
                <a
                  className="menu-link greyish-bg-in-darkmode w-inline-block"
                  data-w-id="8ee07a64-bf7d-c0c9-9753-cd504add6161"
                  href="#"
                >
                  <img
                    alt=""
                    className="image-2"
                    id="new-course-icon"
                    loading="lazy"
                    src="/images/notepad-1-svgrepo-com.svg"
                  />
                  <div className="text-block-3" id="new-course-label">
                    Datasets
                  </div>
                </a>
                <a
                  className="menu-link greyish-bg-in-darkmode w-inline-block"
                  data-w-id="0c66bf80-edc1-c115-223f-705fac678b88"
                  href="#"
                >
                  <img
                    alt=""
                    className="image-2"
                    id="new-course-icon"
                    loading="lazy"
                    src="/images/info-message-svgrepo-com.svg"
                  />
                  <div className="text-block-3" id="new-course-label">
                    Help
                  </div>
                </a>
                <a
                  className="menu-link greyish-bg-in-darkmode w-inline-block"
                  data-w-id="ee79c17b-86ca-1c6c-db5c-5c6942ee181f"
                  href="#"
                >
                  <img
                    alt=""
                    className="image-2"
                    id="new-course-icon"
                    loading="lazy"
                    src="/images/share-2.svg"
                  />
                  <div className="text-block-3" id="new-course-label">
                    Export Chat
                  </div>
                </a>
                <div className="w-layout-hflex theme-switcher-container greyish-bg-in-darkmode whiten-border-in-dark-mode">
                  <img
                    alt=""
                    className="theme-switcher-icon"
                    loading="lazy"
                    src="/images/moon-svgrepo-com.svg"
                  />
                  <img
                    alt=""
                    className="theme-switcher-icon light-mode"
                    loading="lazy"
                    src="/images/sun.svg"
                  />
                </div>
              </div>
              <div className="body-right-side light-grey-bg-dark-mode">
                <div className="upper-area">
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-5.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-2.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-1.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-9.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-8.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-6.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/icon-ai-11.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-4.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-7.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-3.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-14.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-12.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-13.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/icon-ai-10.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/studio.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/copilot.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/mentor.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/premise.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/skills.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/siri-3.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/students.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/course-production.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/siri-2.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/classroom.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/analyctics.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/instructors.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/premise.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 bigger-icons"
                        loading="lazy"
                        src="/images/course-mobile.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/custom.gif"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container light-black-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 medium-icon"
                        loading="lazy"
                        src="/images/ai-academy-p-500.png"
                      />
                    </div>
                    <div className="ai-response-text-container">
                      <h5 className="ai-default-msg whiten-in-dark-mode">
                        <strong className="bold-text">
                          I’m IBL AI Mentor, your AI helpful assistant. Not sure
                          where to start? You can try:
                        </strong>
                        <br />
                      </h5>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Generate a daily study schedule for the next week,
                          including specific times for each subject and any
                          breaks or activities planned
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Can you help me write a research paper on the history
                          of the Roman Empire?
                        </div>
                      </div>
                      <div className="w-layout-vflex default-suggestion-prompts-block all-primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                        <div className="default-suggestion-prompt">
                          Provide 10 multiple choice questions about the period
                          of Reconstruction
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ai-response-container ai-disclaimer-container lighter-grey-bg-dark-mode ai-default-response-container">
                    <div className="ai-gif-container">
                      <img
                        alt=""
                        className="image-3 whiten-in-dark-mode disclaimer-img"
                        loading="lazy"
                        src="/images/info.svg"
                      />
                    </div>
                    <div className="ai-response-text-container default-ai-response-text-container">
                      <div className="text-block-5 whiten-in-dark-mode">
                        The IBL team may review the conversations for quality
                        purposes, as long as you authorize it.
                      </div>
                      <div className="div-block-5">
                        <div className="dont-show-again primary-color-in-dark-mode primary-bg-hover-in-dark-mode">
                          Don't show again
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="templates-block">
                    <div className="user-prompt-template">
                      <Userpromptbox></Userpromptbox>
                    </div>
                    <div className="prepend-actions-template">
                      <div className="w-layout-hflex copy-retry-container all-whiten-in-dark-mode">
                        <a
                          className="response-action-link copy-action-btn w-inline-block"
                          href="#"
                        >
                          <img
                            alt=""
                            className="response-action-icon"
                            loading="lazy"
                            src="/images/clipboard.svg"
                          />
                          <div className="response-action-label">Copy</div>
                        </a>
                        <a
                          className="response-action-link retry-action-btn w-inline-block"
                          href="#"
                        >
                          <img
                            alt=""
                            className="response-action-icon"
                            loading="lazy"
                            src="/images/rotate-ccw.svg"
                          />
                          <div className="response-action-label">Retry</div>
                        </a>
                      </div>
                    </div>
                    <div className="empty-response-template">
                      <div className="ai-response-container ai-prompt-response-container light-black-bg-dark-mode">
                        <div className="ai-gif-container">
                          <img
                            alt=""
                            className="image-3"
                            loading="lazy"
                            src="/images/ibl-gpt-ai.gif"
                          />
                        </div>
                        <div className="ai-response-text-container"></div>
                      </div>
                    </div>
                    <div className="response-templates">
                      <div className="list-template">
                        <div className="ai-response-container ai-prompt-response-container">
                          <div className="ai-gif-container">
                            <img
                              alt=""
                              className="image-3"
                              loading="lazy"
                              src="/images/ibl-gpt-ai.gif"
                            />
                          </div>
                          <div className="ai-response-text-container">
                            <div className="ai-rich-text-zone w-richtext">
                              <h4>Unordered List Template</h4>
                              <p>
                                Smartwatches are becoming increasingly popular,
                                as they offer a variety of features that can
                                make our lives easier and more convenient.
                                However, there are also some disadvantages to
                                consider before buying a smartwatch.
                              </p>
                              <p>Advantages of smartwatches:</p>
                              <ul role="list">
                                <li>
                                  <strong>Convenience:</strong> Smartwatches
                                  allow you to stay connected without having to
                                  take your phone out of your pocket. You can
                                  receive notifications, make and answer calls,
                                  and even control music playback.
                                </li>
                                <li>
                                  <strong>Convenience:</strong> Smartwatches
                                  allow you to stay connected without having to
                                  take your phone out of your pocket. You can
                                  receive notifications, make and answer calls,
                                  and even control music playback.
                                </li>
                              </ul>
                              <p>Disadvantages of smartwatches:</p>
                              <ul role="list">
                                <li>
                                  <strong>Convenience:</strong> Smartwatches
                                  allow you to stay connected without having to
                                  take your phone out of your pocket.
                                </li>
                                <li>
                                  <strong>Convenience:</strong> Smartwatches
                                  allow you to stay connected without having to
                                  take your phone out of your pocket.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="video-template">
                        <div className="ai-response-container ai-prompt-response-container">
                          <div className="ai-gif-container">
                            <img
                              alt=""
                              className="image-3"
                              loading="lazy"
                              src="/images/ibl-gpt-ai.gif"
                            />
                          </div>
                          <div className="ai-response-text-container">
                            <div className="ai-rich-text-zone w-richtext">
                              <h4>Video Template</h4>
                              <p>
                                Deserunt dolore ipsam do wisi consectetuer,
                                aspernatur consequatur, vulputate at ut ea
                                architecto feugiat, distinctio consequatur, a
                                euismod, parturient qui.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="citation-template">
                        <div className="ai-response-container ai-prompt-response-container">
                          <div className="ai-gif-container">
                            <img
                              alt=""
                              className="image-3"
                              loading="lazy"
                              src="/images/ibl-gpt-ai.gif"
                            />
                          </div>
                          <div className="ai-response-text-container">
                            <div className="ai-rich-text-zone w-richtext">
                              <h4>Citation Templates</h4>
                              <p>
                                The solemnity of Saint's Peter and Paul is a
                                liturgical feast in honor of the martyrdom in
                                Rome of the apostles Saint Peter and Saint Paul,
                                which is observed on{' '}
                                <strong>
                                  June 29th <sup>1</sup>
                                </strong>{' '}
                                This solemnity is a celebration of the martyrdom
                                of both saints and their leadership in the early{' '}
                                <strong>
                                  church <sup>2</sup>{' '}
                                </strong>
                                . The date selected being the anniversary of
                                either their death or the translation of their{' '}
                                <strong>
                                  relics <sup>3</sup>
                                </strong>
                                <br />
                              </p>
                            </div>
                            <div className="w-layout-hflex citation-list-container">
                              <div className="text-block-31 whiten-in-dark-mode">
                                Citations :
                              </div>
                              <a className="citation-element" href="#">
                                1. en.wikipedia.com
                              </a>
                              <a className="citation-element" href="#">
                                2. vatican.va
                              </a>
                              <a className="citation-element" href="#">
                                3. google.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-area">
                  <div className="form-block w-form">
                    <form
                      className="form"
                      data-name="user-prompt"
                      data-wf-element-id="2cfcedfe-aeba-88e5-d900-4a8156964a67"
                      data-wf-page-id="650305782bc61751e576521e"
                      id="wf-form-user-prompt"
                      method="get"
                      name="wf-form-user-prompt"
                    >
                      <div className="input-container">
                        <a
                          className="stop-responding-btn primary-color-in-dark-mode primary-border-in-dark-mode"
                          href="#"
                        >
                          Stop Responding
                        </a>
                        <div className="w-layout-vflex guided-question-block">
                          <div className="w-layout-hflex suggestions-prompt-container">
                            <img
                              alt=""
                              className="image-4"
                              loading="lazy"
                              src="/images/question-primary.svg"
                            />
                            <div className="suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                              Generate study materials
                            </div>
                            <div className="suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                              Give me a lesson plan for this course
                            </div>
                            <div className="suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                              Improve my understanding on this topic
                            </div>
                          </div>
                        </div>
                        <div className="user-input-field-block">
                          <input
                            className="user-prompt-input-field black-bg-dark-mode whiten-border-in-dark-mode whiten-in-dark-mode w-input"
                            data-name="Name"
                            id="name"
                            maxLength={256}
                            name="name"
                            placeholder="Enter a prompt here"
                            type="text"
                          />
                          <div className="div-block-8">
                            <div
                              className="user-input-actions-container upload-file-input-action-container greyish-bg-in-darkmode all-whiten-img-on-hover-in-dark-mode"
                              data-w-id="712e5e43-6827-f046-875a-8d254cd1b749"
                            >
                              <div className="tooltip tooltip-user-file-upload">
                                Add Files (5 max, 10Mb each) Accepts pdf, txt,
                                csv, etc.
                              </div>
                              <img
                                alt=""
                                className="image-6"
                                loading="lazy"
                                src="/images/paperclip.svg"
                              />
                            </div>
                            <div
                              className="user-input-actions-container greyish-bg-in-darkmode"
                              data-w-id="c4c241a8-c39d-44d4-4bf4-1cd95b8c7c6c"
                            >
                              <img
                                alt=""
                                className="image-6 whiten-in-dark-mode"
                                loading="lazy"
                                src="/images/microphone-svgrepo-com.svg"
                              />
                              <div className="tooltip tooltip-microphone">
                                Use the microphone
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        className="submit-button w-button"
                        data-wait="Please wait..."
                        defaultValue=""
                        type="submit"
                      />
                      <div className="input-submit-container">
                        <img
                          alt=""
                          className="input-submit-icon"
                          loading="lazy"
                          src="/images/send-svgrepo-com.svg"
                        />
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div className="footer-disclaimer whiten-in-dark-mode">
                    Disclaimer: IBL AI Mentor may display inaccurate information
                    that doesn’t represent our views.
                  </div>
                </div>
              </div>
            </section>
            <div className="modal history-modal">
              <div className="w-layout-vflex modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">History</h3>
                  <a className="default-btn w-button" href="#">
                    New Chat
                  </a>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
                  <div className="w-layout-vflex history-element-container">
                    <div className="history-timeline">Today</div>
                    <div
                      className="w-layout-hflex history-block greyish-bg-in-darkmode"
                      data-w-id="732db3f5-936a-7c07-bf69-1513ba84539a"
                    >
                      <div className="history-left-side">
                        <div className="history-element-title">
                          What's the weather in greenwich?
                        </div>
                      </div>
                      <div className="history-right-side">
                        <div className="history-time">12:22 PM</div>
                        <div className="w-layout-hflex history-action-btn-block">
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/upload.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-vflex history-element-container">
                    <div className="history-timeline">
                      Previous 7 days
                      <br />
                    </div>
                    <div
                      className="w-layout-hflex history-block greyish-bg-in-darkmode"
                      data-w-id="daea0bfa-4b0f-b69a-371d-aa41ba6d0449"
                    >
                      <div className="history-left-side">
                        <div className="history-element-title">
                          What's the weather in greenwich?
                        </div>
                      </div>
                      <div className="history-right-side">
                        <div className="history-time">Yesterday</div>
                        <div className="w-layout-hflex history-action-btn-block">
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/upload.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-hflex history-block greyish-bg-in-darkmode"
                      data-w-id="f97b3dfa-fc2d-7180-8382-5bdcc6502c6a"
                    >
                      <div className="history-left-side">
                        <div className="history-element-title">
                          What's the weather in greenwich?
                        </div>
                      </div>
                      <div className="history-right-side">
                        <div className="history-time">5 days ago</div>
                        <div className="w-layout-hflex history-action-btn-block">
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/upload.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-vflex history-element-container">
                    <div className="history-timeline">
                      Previous 30 days
                      <br />
                    </div>
                    <div
                      className="w-layout-hflex history-block greyish-bg-in-darkmode"
                      data-w-id="1e02b184-398c-4868-df0c-51e1948fe24d"
                    >
                      <div className="history-left-side">
                        <div className="history-element-title">
                          What's the weather in greenwich?
                        </div>
                      </div>
                      <div className="history-right-side">
                        <div className="history-time">8 days ago</div>
                        <div className="w-layout-hflex history-action-btn-block">
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/upload.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-hflex history-block greyish-bg-in-darkmode"
                      data-w-id="1e02b184-398c-4868-df0c-51e1948fe25b"
                    >
                      <div className="history-left-side">
                        <div className="history-element-title">
                          What's the weather in greenwich?
                        </div>
                      </div>
                      <div className="history-right-side">
                        <div className="history-time">15 days ago</div>
                        <div className="w-layout-hflex history-action-btn-block">
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/upload.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-vflex history-element-container last-history-element-container">
                    <div className="history-timeline">
                      May
                      <br />
                    </div>
                    <div
                      className="w-layout-hflex history-block greyish-bg-in-darkmode"
                      data-w-id="a60fa53a-6718-0f11-d0c4-639c08b47a0b"
                    >
                      <div className="history-left-side">
                        <div className="history-element-title">
                          What's the weather in greenwich?
                        </div>
                      </div>
                      <div className="history-right-side">
                        <div className="history-time">35 days ago</div>
                        <div className="w-layout-hflex history-action-btn-block">
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/upload.svg"
                            />
                          </div>
                          <div className="history-action-btn">
                            <img
                              alt=""
                              className="history-action-img"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal prompt-gallery-modal">
              <div className="w-layout-vflex modal-container full-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Suggested Prompts</h3>
                  <a className="default-btn prompt-add-btn w-button" href="#">
                    + Add New Prompt
                  </a>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body">
                  <div className="w-layout-grid prompt-container">
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-b576be1e-6ba4-dfcb-bbf1-cb91dcf3c001-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Find answers to specific questions</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Provide information about [topic]
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/graduation-cap-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Learners
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-af72694c-8686-bb95-68c4-b455ff8555be-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Generate study materials</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Summarize the key points about [topic]
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/graduation-cap-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Learners
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_51750434-663d-833b-fbce-3a0daf0f3ff7-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Assist with research projects</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            List some relevant sources for [topic]
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/graduation-cap-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Learners
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-a7cd9a1e-06e8-090b-f8f6-b8c45befa912-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>
                            Improve understanding of the subject matter
                          </strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Explain [concept] in simple terms
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/graduation-cap-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Learners
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-c759fff2-ef9c-a105-a409-237d50d636d9-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Prepare for exams</strong>{' '}
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            What are the most important things to know about
                            [topic] for a test?
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/graduation-cap-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Learners
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_5a505de0-516a-2a64-e67d-16956e5a70c0-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>
                            <em>Generating Educational Content</em>
                          </strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Provide examples of [topic] for [grade level]
                            students
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/presentation-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Instructors
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_9e603d53-b516-ca5b-44d6-a98f039371be-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Lesson Planning</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Explain the key concepts of [topic] in simple
                            language
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/presentation-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Instructors
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_5068f3b8-e576-1e69-2bdf-072c5c46ac84-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Personalized Learning Experiences</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Provide activities related to [topic] for [grade
                            level] students
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/presentation-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Instructors
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-bd8f269d-4a7e-2c14-1de7-265adbd63344-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Summaries of complex topics</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Provide a summary of [topic]
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/presentation-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Instructors
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_1af02b37-6ae8-68eb-1777-b7601022c2da-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Creating interactive quizzes</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Create a quiz with [number] questions on [topic]
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/presentation-1.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          For Instructors
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-c0b90eb4-6a75-f47f-543a-0b7e429cda10-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Write an Essay</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Write an essay on the Civil War in 1000 words on the
                            title "The North vs the South” <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/chevron-right-square.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          My Prompt #1
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_9047bc1f-67de-2625-2129-aeb12369f037-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">
                          <strong>Suggest Ideas</strong>
                        </h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          <em>
                            Suggest me some ideas on the origin of the Silicon
                            Valley boom
                            <br />
                          </em>
                        </div>
                        <a className="prompt-edit-btn" href="#">
                          Edit
                        </a>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer prompt-el-footer-no-hov prompt-el-footer-primary-bg">
                        <img
                          alt=""
                          className="prompt-el-footer-icon whiteing-img"
                          loading="lazy"
                          src="/images/chevron-right-square.svg"
                        />
                        <div className="prompt-el-action-text prompt-el-action-text-white">
                          My Prompt #2
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex add-suggested-prompt-block"
                      data-w-id="b49f10dc-e0b4-c7e5-4451-56865fbcf1a8"
                      id="w-node-b49f10dc-e0b4-c7e5-4451-56865fbcf1a8-e576521e"
                    >
                      <div className="w-layout-hflex flex-block-18">
                        <img
                          alt=""
                          className="image-28"
                          loading="lazy"
                          src="/images/plus-4.svg"
                        />
                      </div>
                      <h5 className="add-new-prompt-label black-color-in-dark-mode">
                        Add New Prompt
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal datasets-modal">
              <div className="w-layout-vflex modal-container full-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Datasets</h3>
                  <a className="default-btn w-button" href="#">
                    New Resource
                  </a>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body">
                  <div className="w-layout-grid prompt-container">
                    <div className="w-layout-vflex prompt-element">
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">PDF</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          project-execution-map.pdf
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_9185f9b8-cd24-ba2c-2492-2cf95e6df7e4-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-b4bcbbc4-4267-f9b9-f5ac-26fd18f8c710-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_92fd9786-901f-3562-c5c4-e56c5fa930df-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_6058983d-a036-bfab-a90b-7047e792d469-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_097c02ac-e437-5106-4e8e-cc17689e8408-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-c46101f8-a0ce-b7a5-a4f0-088197d66407-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_284bf844-f06b-74eb-3609-10a2208f3c59-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-_6d6cb5d0-af0e-20d6-cf58-c504a8086be8-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-d2369ff8-d840-495a-bf4a-8ac751a369d8-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-fd4f2f42-6ef7-9af6-b549-e27032c3243f-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                    <div
                      className="w-layout-vflex prompt-element"
                      id="w-node-e48dc68a-76ec-d559-3d8d-164e5df00028-e576521e"
                    >
                      <div className="prompt-el-header">
                        <h5 className="prompt-el-title">URL</h5>
                      </div>
                      <div className="prompt-el-inner">
                        <div className="prompt-el-description">
                          www.ibleducation.com
                        </div>
                      </div>
                      <div className="w-layout-hflex prompt-el-footer">
                        <div className="prompt-el-action-text">LOAD</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal help-modal">
              <div className="w-layout-vflex modal-container help-modal-container help-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Help</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body help-modal-body">
                  <div className="w-layout-vflex flex-block-13">
                    <div>Popular Resources</div>
                  </div>
                  <div className="w-layout-hflex help-element">
                    <div className="help-el-icon-container">
                      <img
                        alt=""
                        className="help-el-icon-img"
                        loading="lazy"
                        src="/images/file-text-3.svg"
                      />
                    </div>
                    <div className="help-el-description">Use IBL AI</div>
                  </div>
                  <div className="w-layout-hflex help-element">
                    <div className="help-el-icon-container">
                      <img
                        alt=""
                        className="help-el-icon-img"
                        loading="lazy"
                        src="/images/file-text-3.svg"
                      />
                    </div>
                    <div className="help-el-description">
                      Where can you use IBL AI
                    </div>
                  </div>
                  <div className="w-layout-hflex help-element">
                    <div className="help-el-icon-container">
                      <img
                        alt=""
                        className="help-el-icon-img"
                        loading="lazy"
                        src="/images/file-text-3.svg"
                      />
                    </div>
                    <div className="help-el-description">Sign in to IBL AI</div>
                  </div>
                  <div className="w-layout-hflex help-element">
                    <div className="help-el-icon-container">
                      <img
                        alt=""
                        className="help-el-icon-img"
                        loading="lazy"
                        src="/images/file-text-3.svg"
                      />
                    </div>
                    <div className="help-el-description">
                      Manage &amp; delete your IBL AI Activity
                    </div>
                  </div>
                  <div className="w-layout-hflex help-element">
                    <div className="help-el-icon-container">
                      <img
                        alt=""
                        className="help-el-icon-img"
                        loading="lazy"
                        src="/images/file-text-3.svg"
                      />
                    </div>
                    <div className="help-el-description">
                      IBL AI Privacy Notice
                    </div>
                  </div>
                  <div className="help-search-input-block w-form">
                    <form
                      className="help-search-form"
                      data-name="Email Form"
                      data-wf-element-id="3f69cca6-0506-dab3-0ea6-dad18e6db4e0"
                      data-wf-page-id="650305782bc61751e576521e"
                      id="email-form"
                      method="get"
                      name="email-form"
                    >
                      <input
                        className="help-search-input w-input"
                        data-name="Name 2"
                        id="name-2"
                        maxLength={256}
                        name="name-2"
                        placeholder="Search Help"
                        type="text"
                      />
                      <img
                        alt=""
                        className="image-25"
                        loading="lazy"
                        src="/images/search-svgrepo-com.svg"
                      />
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div className="help-seperator-line"></div>
                  <div className="w-layout-hflex help-element help-element-report-problem">
                    <div className="help-el-icon-container help-el-report-problem">
                      <img
                        alt=""
                        className="help-el-icon-img"
                        loading="lazy"
                        src="/images/info-message-svgrepo-com-blueish.svg"
                      />
                    </div>
                    <div className="help-el-description help-report-problem">
                      Report a problem
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal export-modal">
              <div className="w-layout-vflex modal-container export-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Export Chat</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body export-chat-modal-body">
                  <div className="w-layout-vflex chat-export-container">
                    <div className="chat-export-container-top-block">
                      <div className="w-layout-hflex chat-export-header">
                        <div className="chat-export-header-block">
                          <div className="chat-export-icon-container">
                            <img
                              alt=""
                              className="image-26"
                              loading="lazy"
                              src="/images/user.svg"
                            />
                          </div>
                          <p className="paragraph-2">
                            In law what harm could i suffer when i see two
                            people kissing in public
                          </p>
                        </div>
                      </div>
                      <div className="w-layout-hflex chat-export-header chat-export-body">
                        <div className="chat-export-header-block">
                          <div className="chat-export-icon-container">
                            <img
                              alt=""
                              className="image-26"
                              loading="lazy"
                              src="/images/bot.svg"
                            />
                          </div>
                          <p className="paragraph-2">
                            As an AI language model, I can provide you with some
                            general information, but please keep in mind that
                            laws vary across jurisdictions, and I am not a
                            lawyer. If you have concerns about a specific
                            jurisdiction, it's always best to consult with a
                            legal professional who can provide advice based on
                            the laws applicable to your situation.
                            <br />
                            <br />
                            As an AI language model, I can provide you with some
                            general information, but please keep in mind that
                            laws vary across jurisdictions, and I am not a
                            lawyer. If you have concerns about a specific
                            jurisdiction, it's always best to consult with a
                            legal professional who can provide advice based on
                            the laws applicable to your situation.
                            <br />
                            <br />
                            As an AI language model, I can provide you with some
                            general information, but please keep in mind that
                            laws vary across jurisdictions, and I am not a
                            lawyer. If you have concerns about a specific
                            jurisdiction, it's always best to consult with a
                            legal professional who can provide advice based on
                            the laws applicable to your situation.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-hflex chat-export-container-bottom-block">
                      <div className="w-layout-vflex chat-export-details-block">
                        <h5 className="chat-export-details-title">
                          Public Kissing: Legal Considerations
                        </h5>
                        <div className="chat-export-details-label">
                          July 7, 2023
                        </div>
                      </div>
                      <div className="chat-export-icon-container chat-export-more-icon-container">
                        <img
                          alt=""
                          className="image-26 export-chat-more-img"
                          loading="lazy"
                          src="/images/more-horizontal-3.svg"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-hflex flex-block-17">
                    <div className="button-with-icon">
                      <img
                        alt=""
                        className="button-icon"
                        loading="lazy"
                        src="/images/download.svg"
                        width={70}
                      />
                      <div className="button-text">Download Chat</div>
                    </div>
                    <div className="button-with-icon">
                      <img
                        alt=""
                        className="button-icon no-invert"
                        loading="lazy"
                        src="/images/link.svg"
                        width={70}
                      />
                      <div className="button-text">Copy Link</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal manage-account-modal">
              <div className="w-layout-vflex modal-container export-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Manage Account</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body manage-account-modal-body">
                  <div className="manage-account-form-block w-form">
                    <form
                      className="manage-account-form"
                      data-name="Email Form 2"
                      data-wf-element-id="7fe92682-aa47-c52e-5c20-6ffcd8b4314f"
                      data-wf-page-id="650305782bc61751e576521e"
                      id="email-form-2"
                      method="get"
                      name="email-form-2"
                    >
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name">
                          Organization name
                        </label>
                        <div className="form-input-desc">
                          Human-friendly label for your organization, shown in
                          user interfaces
                        </div>
                        <input
                          className="form-input w-input"
                          custom-value="Personal"
                          data-name="organisation-name"
                          id="organisation-name"
                          maxLength={256}
                          name="organisation-name"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          Organization ID
                        </label>
                        <div className="form-input-desc">
                          Identifier for this organization sometimes used in API
                          requests
                        </div>
                        <input
                          className="form-input form-input-disabled w-input"
                          data-name="Organisation Name 2"
                          id="organisation-name-2"
                          maxLength={256}
                          name="organisation-name-2"
                          placeholder="0cL5tH4SKX36wf55z"
                          type="text"
                        />
                      </div>
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          First Name
                        </label>
                        <div className="form-input-desc">Your first name</div>
                        <input
                          className="form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 3"
                          id="organisation-name-3"
                          maxLength={256}
                          name="organisation-name-3"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          Last Name
                        </label>
                        <div className="form-input-desc">Your last name</div>
                        <input
                          className="form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 3"
                          id="organisation-name-3"
                          maxLength={256}
                          name="organisation-name-3"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          Username
                        </label>
                        <div className="form-input-desc">Must be unique</div>
                        <input
                          className="form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 3"
                          id="organisation-name-3"
                          maxLength={256}
                          name="organisation-name-3"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          Email
                        </label>
                        <div className="form-input-desc">
                          A confirmation link would be sent to you immediately
                        </div>
                        <input
                          className="form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 3"
                          id="organisation-name-3"
                          maxLength={256}
                          name="organisation-name-3"
                          placeholder="hi@example.com"
                          type="text"
                        />
                      </div>
                      <a
                        className="default-btn form-submit-btn w-button"
                        href="#"
                      >
                        Save
                      </a>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal billing-overview-modal">
              <div className="w-layout-vflex modal-container billing-overview-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Billing Overview</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body billing-overview-modal-body">
                  <h4 className="heading-6">Free Trial</h4>
                  <div className="w-layout-vflex upgrade-account-block">
                    <div>
                      You are currently on a free trial of the IBL API. This
                      does not reflect the status of your IBL-GPT account.
                    </div>
                    <a
                      className="default-btn upgrade-account-btn w-button"
                      href="https://checkout.stripe.dev/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Upgrade Your Account
                    </a>
                  </div>
                  <div className="w-layout-hflex billing-overview-submenu-block">
                    <div
                      className="w-layout-hflex billing-overview-submenu"
                      data-w-id="502d36e7-9841-5ed5-077b-103cd09297ee"
                    >
                      <div className="billing-overview-submenu-icon-container">
                        <img
                          alt=""
                          className="billing-overview-submenu-icon"
                          loading="lazy"
                          src="/images/credit-card.svg"
                        />
                      </div>
                      <div className="billing-overview-submenu-desc">
                        <h5 className="billing-overview-submenu-desc-title">
                          Payment Method
                        </h5>
                        <div className="text-block-20 text-block-21 text-block-22 text-block-23 text-block-24 billing-overview-submenu-desc-label">
                          Add or change payment method
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-hflex billing-overview-submenu"
                      data-w-id="504a84e7-e63f-f6f4-7a5d-cd8d5fb123ef"
                    >
                      <div className="billing-overview-submenu-icon-container">
                        <img
                          alt=""
                          className="billing-overview-submenu-icon"
                          loading="lazy"
                          src="/images/file.svg"
                        />
                      </div>
                      <div className="billing-overview-submenu-desc">
                        <h5 className="billing-overview-submenu-desc-title">
                          Billing history
                        </h5>
                        <div className="text-block-20 text-block-21 text-block-22 text-block-23 text-block-24 billing-overview-submenu-desc-label">
                          View past and current invoices
                        </div>
                      </div>
                    </div>
                    <div
                      className="w-layout-hflex billing-overview-submenu"
                      data-w-id="55bd7758-4e82-5dd3-3a2f-55af8288befd"
                    >
                      <div className="billing-overview-submenu-icon-container">
                        <img
                          alt=""
                          className="billing-overview-submenu-icon"
                          loading="lazy"
                          src="/images/sliders.svg"
                        />
                      </div>
                      <div className="billing-overview-submenu-desc">
                        <h5 className="billing-overview-submenu-desc-title">
                          Usage limits
                        </h5>
                        <div className="text-block-20 text-block-21 text-block-22 text-block-23 text-block-24 billing-overview-submenu-desc-label">
                          Set monthly spend limits
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal payment-method-modal">
              <div className="w-layout-vflex modal-container payment-method-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Payment Methods</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body payment-method-modal-body">
                  <div className="w-layout-grid payment-method-block">
                    <div
                      className="w-layout-vflex payment-method-container"
                      id="w-node-_7742785c-c334-11bc-b54a-fce8d3bead4c-e576521e"
                    >
                      <div className="w-layout-hflex card-number-block">
                        <img
                          alt=""
                          className="image-27"
                          loading="lazy"
                          sizes="100vw"
                          src="/images/visa.png"
                          srcSet="images/visa-p-500.png 500w, images/visa.png 512w"
                        />
                        <div className="card-number-text">**** 7438</div>
                      </div>
                      <div className="card-text-info">Expires 11/2025</div>
                      <div className="card-text-info">Default</div>
                    </div>
                    <div
                      className="w-layout-vflex payment-method-container"
                      id="w-node-fcbd4778-f8a0-08d1-d9d3-88a63a7920aa-e576521e"
                    >
                      <div className="w-layout-hflex card-number-block">
                        <img
                          alt=""
                          className="image-27"
                          loading="lazy"
                          sizes="100vw"
                          src="/images/visa.png"
                          srcSet="images/visa-p-500.png 500w, images/visa.png 512w"
                        />
                        <div className="card-number-text">**** 7438</div>
                      </div>
                      <div className="card-text-info">Expires 11/2025</div>
                      <div className="card-text-info">Fallback</div>
                    </div>
                    <div
                      className="w-layout-vflex payment-method-container"
                      id="w-node-_117af3f1-7b88-7102-671f-e0fdeba598be-e576521e"
                    >
                      <div className="w-layout-hflex card-number-block">
                        <img
                          alt=""
                          className="image-27"
                          loading="lazy"
                          sizes="100vw"
                          src="/images/visa.png"
                          srcSet="images/visa-p-500.png 500w, images/visa.png 512w"
                        />
                        <div className="card-number-text">**** 7438</div>
                      </div>
                      <div className="card-text-info">Expires 11/2025</div>
                      <div className="card-text-info">Fallback</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal billing-history-modal">
              <div className="w-layout-vflex modal-container billing-history-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Billing History</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body billing-history-modal-body">
                  <div className="text-block-25">
                    Showing invoices within the past 12 months
                  </div>
                  <div className="w-layout-vflex table-container">
                    <div className="w-layout-hflex table-header">
                      <div className="invoice-block">
                        <h5 className="table-title">INVOICE</h5>
                      </div>
                      <div className="status-block">
                        <h5 className="table-title">STATUS</h5>
                      </div>
                      <div className="amount-block">
                        <h5 className="table-title">AMOUNT</h5>
                      </div>
                      <div className="created-at-block">
                        <h5 className="table-title">CREATED</h5>
                      </div>
                      <div className="action-block"></div>
                    </div>
                    <div className="w-layout-hflex table-body-row">
                      <div className="invoice-block">
                        <div className="table-text">BE678HKJKJK</div>
                      </div>
                      <div className="status-block">
                        <div className="div-block-34">
                          <div className="status-element">Paid</div>
                        </div>
                      </div>
                      <div className="amount-block">
                        <div className="table-text">$61.03</div>
                      </div>
                      <div className="created-at-block">
                        <div className="table-text">Jul 21, 2023 7:23 PM</div>
                      </div>
                      <div className="action-block">
                        <a className="link" href="#">
                          View
                        </a>
                      </div>
                    </div>
                    <div className="w-layout-hflex table-body-row">
                      <div className="invoice-block">
                        <div className="table-text">BE678HKJKJK</div>
                      </div>
                      <div className="status-block">
                        <div className="div-block-34">
                          <div className="status-element status-element-unpaid">
                            Unpaid
                          </div>
                        </div>
                      </div>
                      <div className="amount-block">
                        <div className="table-text">$33.90</div>
                      </div>
                      <div className="created-at-block">
                        <div className="table-text">Jul 17, 2023 5:45 PM</div>
                      </div>
                      <div className="action-block">
                        <a className="link" href="#">
                          View
                        </a>
                      </div>
                    </div>
                    <div className="w-layout-hflex table-body-row">
                      <div className="invoice-block">
                        <div className="table-text">BE678HKJKJK</div>
                      </div>
                      <div className="status-block">
                        <div className="div-block-34">
                          <div className="status-element">Paid</div>
                        </div>
                      </div>
                      <div className="amount-block">
                        <div className="table-text">$123.55</div>
                      </div>
                      <div className="created-at-block">
                        <div className="table-text">Jul 05, 2023 1:45 AM</div>
                      </div>
                      <div className="action-block">
                        <a className="link" href="#">
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal usage-limit-modal">
              <div className="w-layout-vflex modal-container usage-limit-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Usage Limits</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body usage-limit-modal-body">
                  <div className="usage-limit-wysiwyg-block w-richtext">
                    <p>
                      Manage your spending by configuring usage limits.
                      Notification emails triggered by reaching these limits
                      will be sent to members of your organization with the{' '}
                      <strong>Owner</strong> role.
                    </p>
                    <p>
                      There may be a delay in enforcing any limits, and you are
                      responsible for any overage incurred. We recommend
                      checking your usage tracking dashboard regularly to
                      monitor your spend.
                    </p>
                    <h5>Approved Usage limit</h5>
                    <p>
                      The maximum usage IBL-AI allowed for your organisation
                      each month.{' '}
                      <a href="https://ibl-ai-chatbot-v1.webflow.io/#">
                        Request increase
                      </a>
                    </p>
                    <h3>$9,000.00</h3>
                    <h5>Current Usage</h5>
                    <p>
                      Your total usage so far in July (UTC). Note that this may
                      include usage covered by a free trial or other credits, so
                      your monthly might be less than the value shown here.{' '}
                      <a href="https://ibl-ai-chatbot-v1.webflow.io/#">
                        View usage records
                      </a>
                    </p>
                    <h3>$24.89</h3>
                  </div>
                  <div className="manage-account-form-block usage-limit-form-block w-form">
                    <form
                      className="manage-account-form usage-limit-form"
                      data-name="Email Form 2"
                      data-wf-element-id="bf14da3d-2156-05f7-a238-fb39944625c7"
                      data-wf-page-id="650305782bc61751e576521e"
                      id="email-form-2"
                      method="get"
                      name="email-form-2"
                    >
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          Hard Limit
                        </label>
                        <div className="form-input-desc usage-limit-form-input-desc">
                          When your organisation reaches this usage threshold
                          each month, subsequent request will be rejected
                        </div>
                        <input
                          className="form-input usage-limit-form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 4"
                          id="organisation-name-4"
                          maxLength={256}
                          name="organisation-name-4"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <div className="w-layout-vflex input-block">
                        <label className="form-input-label" htmlFor="name-3">
                          Soft Limit
                        </label>
                        <div className="form-input-desc usage-limit-form-input-desc">
                          When your organisation reaches this usage threshold
                          each month, a notification email will be sent
                        </div>
                        <input
                          className="form-input usage-limit-form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 4"
                          id="organisation-name-4"
                          maxLength={256}
                          name="organisation-name-4"
                          placeholder=""
                          type="text"
                        />
                      </div>
                      <a
                        className="default-btn form-submit-btn w-button"
                        href="#"
                      >
                        Save
                      </a>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal integration-modal">
              <div className="w-layout-vflex modal-container integration-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">API Keys</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body integration-modal-body">
                  <div className="integration-text">
                    Your secret API keys are listed below. Please note that we
                    do not display your secret API keys again after you generate
                    them.
                  </div>
                  <div className="integration-text">
                    Do not share your API key with others, or expose it in the
                    browser or other client-side code. In order to protect the
                    security of your account, IBL may also automatically rotate
                    any API key that we've found has leaked publicly.
                  </div>
                  <div className="w-layout-vflex table-container">
                    <div className="w-layout-hflex table-header api-key-table-header">
                      <div className="api-name-block">
                        <h5 className="table-title">NAME</h5>
                      </div>
                      <div className="api-key-block">
                        <h5 className="table-title">KEY</h5>
                      </div>
                      <div className="api-created-block">
                        <h5 className="table-title">CREATED</h5>
                      </div>
                      <div className="api-last-used-block">
                        <h5 className="table-title">LAST USED</h5>
                      </div>
                      <div className="api-action-block"></div>
                    </div>
                    <div className="w-layout-hflex table-body-row">
                      <div className="api-name-block">
                        <div className="table-text">Secret key</div>
                      </div>
                      <div className="api-key-block">
                        <div className="table-text">sk-.....jhj</div>
                      </div>
                      <div className="api-created-block">
                        <div className="table-text">Jul 19, 2023</div>
                      </div>
                      <div className="api-last-used-block">
                        <div className="table-text">Jul 20, 2023</div>
                      </div>
                      <div className="api-action-block">
                        <div className="w-layout-hflex api-action-container">
                          <div className="api-action-icon-container greyish-bg-in-darkmode">
                            <img
                              alt=""
                              className="api-action-icon whiten-in-dark-mode"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="api-action-icon-container greyish-bg-in-darkmode">
                            <img
                              alt=""
                              className="api-action-icon whiten-in-dark-mode"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-hflex table-body-row">
                      <div className="api-name-block">
                        <div className="table-text">Secret key</div>
                      </div>
                      <div className="api-key-block">
                        <div className="table-text">sk-.....jhj</div>
                      </div>
                      <div className="api-created-block">
                        <div className="table-text">Jul 19, 2023</div>
                      </div>
                      <div className="api-last-used-block">
                        <div className="table-text">Jul 20, 2023</div>
                      </div>
                      <div className="api-action-block">
                        <div className="w-layout-hflex api-action-container">
                          <div className="api-action-icon-container greyish-bg-in-darkmode">
                            <img
                              alt=""
                              className="api-action-icon whiten-in-dark-mode"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="api-action-icon-container greyish-bg-in-darkmode">
                            <img
                              alt=""
                              className="api-action-icon whiten-in-dark-mode"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-hflex table-body-row">
                      <div className="api-name-block">
                        <div className="table-text">Secret key</div>
                      </div>
                      <div className="api-key-block">
                        <div className="table-text">sk-.....jhj</div>
                      </div>
                      <div className="api-created-block">
                        <div className="table-text">Jul 19, 2023</div>
                      </div>
                      <div className="api-last-used-block">
                        <div className="table-text">Jul 20, 2023</div>
                      </div>
                      <div className="api-action-block">
                        <div className="w-layout-hflex api-action-container">
                          <div className="api-action-icon-container greyish-bg-in-darkmode">
                            <img
                              alt=""
                              className="api-action-icon whiten-in-dark-mode"
                              loading="lazy"
                              src="/images/edit.svg"
                            />
                          </div>
                          <div className="api-action-icon-container greyish-bg-in-darkmode">
                            <img
                              alt=""
                              className="api-action-icon whiten-in-dark-mode"
                              loading="lazy"
                              src="/images/trash-2.svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    className="default-btn w-button"
                    data-w-id="66836f4f-d2f1-5709-b145-64f9675744e4"
                    href="#"
                  >
                    Create A New Secret Key
                  </a>
                  <h4 className="heading-7">Default Organization</h4>
                  <div>
                    If you belong to multiple organisations, this settings
                    controls which organization is used be default when making
                    requests with the API keys above
                  </div>
                  <div className="form-block-2 w-form">
                    <form
                      data-name="Email Form 3"
                      data-wf-element-id="98ec6b17-f48d-2c95-80fc-fbb7619bb713"
                      data-wf-page-id="650305782bc61751e576521e"
                      id="email-form-3"
                      method="get"
                      name="email-form-3"
                    >
                      <select
                        className="select-field w-select"
                        data-name="organisation-choice"
                        id="organisation-choice"
                        name="organisation-choice"
                      >
                        <option value="">ibleducation.com</option>
                      </select>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                  <div className="text-block-26">
                    Note: You can also specify which organization to use for
                    each API request
                  </div>
                </div>
              </div>
            </div>
            <div className="modal create-secret-key-modal">
              <div className="w-layout-vflex modal-container create-secret-key-modal-container">
                <div className="w-layout-hflex modal-header">
                  <h3 className="modal-header-title">Create New Secret Key</h3>
                  <div
                    className="modal-close-wrapper"
                    data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
                    prop-events-names="onClick"
                    prop-events-value-onclick="handleModalCloseClick"
                  >
                    <img alt="" loading="lazy" src="/images/close_1close.png" />
                  </div>
                </div>
                <div className="w-layout-vflex modal-body create-secret-key-modal-body">
                  <div className="secret-key-form-block w-form">
                    <form
                      className="manage-account-form secret-key-form"
                      data-name="Email Form 2"
                      data-wf-element-id="ac494637-d09c-3688-b1d4-e5c96ecaaa9a"
                      data-wf-page-id="650305782bc61751e576521e"
                      id="email-form-2"
                      method="get"
                      name="email-form-2"
                    >
                      <div className="w-layout-vflex input-block">
                        <div className="w-layout-hflex flex-block-19">
                          <label className="form-input-label" htmlFor="name-3">
                            Hard Limit
                          </label>
                          <div className="form-input-desc secret-key-form-input-desc">
                            Optional
                          </div>
                        </div>
                        <input
                          className="form-input secret-key-form-input w-input"
                          custom-value="Personal"
                          data-name="Organisation Name 4"
                          id="organisation-name-4"
                          maxLength={256}
                          name="organisation-name-4"
                          placeholder="My Test Key"
                          type="text"
                        />
                      </div>
                      <div className="w-layout-hflex flex-block-20">
                        <a
                          className="default-btn form-submit-btn form-cancel-btn w-button"
                          data-w-id="ac494637-d09c-3688-b1d4-e5c96ecaaaa7"
                          href="#"
                        >
                          Cancel
                        </a>
                        <a
                          className="default-btn form-submit-btn w-button"
                          data-w-id="fa301b61-b17e-2fcb-25e2-efa67e209d6c"
                          href="#"
                        >
                          Save
                        </a>
                      </div>
                    </form>
                    <div className="w-form-done">
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div className="w-form-fail">
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </span>
      </span>
    );
  }
}

export default HomeCopyView;

/* eslint-enable */
