import React from 'react';
import useUserItem from '../../hooks/users/useUserItem';

export default function UserItem({ user }) {
  const {
    role,
    setRole,
    adminMenuRef,
    setShowAdminMenu,
    showAdminMenu,
    handleUpdateRole,
    handleActiveStatusToggle,
  } = useUserItem(user);
  return (
    <tr key={user.user_id} className="table-body-row">
      <td>
        <div className="table-text">
          {new Date(user.added_on).toLocaleDateString()}
        </div>
      </td>
      <td>
        <div className="table-text table-text-italic">{user.username}</div>
      </td>
      <td>
        <div className="table-text">{user.email}</div>
      </td>
      <td style={{ marginLeft: '150px' }}>
        <div
          onClick={() => {
            setShowAdminMenu((prevState) => !prevState);
          }}
          data-hover="false"
          data-delay="0"
          className=""
        >
          <div className="user-permission-dropdown-toggle w-dropdown-toggle">
            <div className="user-permission-dropdown-toggle-icon w-icon-dropdown-toggle"></div>
            <div className="">
              <span>{role}</span>
            </div>
          </div>
          {showAdminMenu && (
            <nav
              ref={adminMenuRef}
              className=" w--open"
              style={{
                zIndex: 99999,
                width: '50px !important',
                position: 'absolute',
                backgroundColor: 'white',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <a
                onClick={() => {
                  handleUpdateRole(user, 'student');
                  setRole('Student');
                }}
                href="#"
                className="user-permission-dropdown-list-element greyish-bg-in-darkmode w-dropdown-link"
              >
                Student
              </a>
              <a
                onClick={() => {
                  handleUpdateRole(user, 'admin');
                  setRole('Admin');
                }}
                href="#"
                className="user-permission-dropdown-list-element greyish-bg-in-darkmode w-dropdown-link"
              >
                Admin
              </a>
            </nav>
          )}
        </div>
      </td>

      <td>
        <label className="user-switcher">
          <input
            onClick={() => {
              handleActiveStatusToggle(user);
            }}
            type="checkbox"
            defaultChecked={user.active}
          />
          <span className="user-switcher-slider round"></span>
        </label>
      </td>
    </tr>
  );
}
