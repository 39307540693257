import { useState, useEffect } from 'react';
import { api } from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';

export default function useInvitedUsers() {
  const [invitations, setInvitations] = useState(null);
  const auth = useSelector((state) => state.auth.data);
  const [params, setParams] = useState({
    platform_key: auth?.tenant?.key,
    page: 1,
  });
  const [loading, setLoading] = useState(false);

  const handleFetchInvitationsSuccess = (response) => {
    setLoading(false);
    setInvitations(response?.body);
  };

  useEffect(() => {
    setLoading(true);
    api.ibldmplatform.fetchPlatformInvitations(
      params,
      handleFetchInvitationsSuccess,
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, [params]);

  return { loading, invitations, setParams };
}
