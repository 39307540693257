import { TrialTopHeader } from '@iblai/ibl-web-react-common';
import useSubscriptionStatus from '../../hooks/subscription/useSubscriptionStatus';

export default function SubscriptionInactiveBanner() {
  const tooltip = `Your subscription is currently inactive. You need to renew or update your payment details`;
  const pillDisplay = `Update`;
  const description = `Your subscription is currently inactive. You need to renew or update your payment details`;

  const { show, handleGotoPaymentUpdate } = useSubscriptionStatus();

  return (
    <>
      {show && (
        <TrialTopHeader
          descriptionText={description}
          pillDisplayText={pillDisplay}
          tooltipText={tooltip}
          handleClick={handleGotoPaymentUpdate}
          fixedClassNamesToMove=".header, .main-content"
        />
      )}
    </>
  );
}
