import { useDispatch, useSelector } from 'react-redux';
import { promptActions } from '../../../lib/redux/prompts/slice';

export default function usePromptActiveCategory(category) {
  const dispatch = useDispatch();
  const prompts = useSelector((state) => state.prompts.data);

  const handlePromptGalleryCategoryClick = () => {
    dispatch(
      promptActions.promptsUpdated({ ...prompts, activeCategory: category })
    );
  };

  return {
    handlePromptGalleryCategoryClick,
    active: prompts?.activeCategory === category,
  };
}
