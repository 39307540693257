import PageLoader from '../loader/page-loader';
import { useEffect } from 'react';
import { completeAuthFromToken } from '../../utils/auth';
import { toast } from 'react-toastify';

export default function ChromeExtSSOLogin() {
  const urlParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const token = urlParams.get('token');
    if (token) {
      completeAuthFromToken(token);
      window.location.assign('/chrome-ext-login-complete');
    } else {
      toast.error('Missing auth token');
    }
  }, []);
  return (
    <>
      <PageLoader />
    </>
  );
}
