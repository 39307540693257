import React from "react";
import "./Usagelimitmodalbox.css";


const Usagelimitmodalbox = props => {
    
    return (
<div className="modal usage-limit-modal">
<div className="w-layout-vflex modal-container usage-limit-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">Usage Limits</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="/images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body usage-limit-modal-body">
<div className="usage-limit-wysiwyg-block w-richtext">
<p>Manage your spending by configuring usage limits. Notification emails triggered by reaching these limits will be sent to members of your organization with the <strong>Owner</strong> role.</p>
<p>There may be a delay in enforcing any limits, and you are responsible for any overage incurred. We recommend checking your usage tracking dashboard regularly to monitor your spend.</p>
<h5>Approved Usage limit</h5>
<p>The maximum usage IBL-AI allowed for your organisation each month. <a href="https://ibl-ai-chatbot-v1.webflow.io/#">Request increase</a>
</p>
<h3>$9,000.00</h3>
<h5>Current Usage</h5>
<p>Your total usage so far in July (UTC). Note that this may include usage covered by a free trial or other credits, so your monthly might be less than the value shown here. <a href="https://ibl-ai-chatbot-v1.webflow.io/#">View usage records</a>
</p>
<h3>$24.89</h3>
</div>
<div className="manage-account-form-block usage-limit-form-block w-form">
<form className="manage-account-form usage-limit-form" data-name="Email Form 2" data-wf-element-id="bf14da3d-2156-05f7-a238-fb39944625c7" data-wf-page-id="650305782bc61751e5765214" id="email-form-2" method="get" name="email-form-2">
<div className="w-layout-vflex input-block"><label className="form-input-label" htmlFor="name-3">Hard Limit</label>
<div className="form-input-desc usage-limit-form-input-desc">When your organisation reaches this usage threshold each month, subsequent request will be rejected</div><input className="form-input usage-limit-form-input w-input" custom-value="Personal" data-name="Organisation Name 4" id="organisation-name-4" maxLength={256} name="organisation-name-4" placeholder="" type="text"/>
</div>
<div className="w-layout-vflex input-block"><label className="form-input-label" htmlFor="name-3">Soft Limit</label>
<div className="form-input-desc usage-limit-form-input-desc">When your organisation reaches this usage threshold each month, a notification email will be sent</div><input className="form-input usage-limit-form-input w-input" custom-value="Personal" data-name="Organisation Name 4" id="organisation-name-4" maxLength={256} name="organisation-name-4" placeholder="" type="text"/>
</div>
<a className="default-btn form-submit-btn w-button" href="#">Save</a>
</form>
<div className="w-form-done">
<div>Thank you! Your submission has been received!</div>
</div>
<div className="w-form-fail">
<div>Oops! Something went wrong while submitting the form.</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Usagelimitmodalbox;