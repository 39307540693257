import { useDispatch, useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { promptActions } from '../../../lib/redux/prompts/slice';
import { useEffect, useState } from 'react';

export default function usePromptCategories() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const auth = useSelector((state) => state.auth.data);
  const prompts = useSelector((state) => state.prompts.data);
  const endpoint = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompts/category`;
  const dispatch = useDispatch();
  const api = useApi();

  const fetchCategories = async () => {
    setLoading(true);
    const response = await api.get(endpoint);
    if (response?.data) {
      dispatch(
        promptActions.promptsUpdated({
          ...prompts,
          availableCategories: response?.data,
        })
      );
    } else {
      setError(response?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!prompts?.availableCategories) {
      fetchCategories();
    }
  }, []);

  return { loading, error };
}
