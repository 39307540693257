import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { promptActions } from '../../../lib/redux/prompts/slice';
import useApi from '../base/useApi';

export default function usePrompts() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const auth = useSelector((state) => state.auth.data);
  const prompts = useSelector((state) => state.prompts.data);

  const api = useApi();
  let endpoint = `/api/ai-prompt/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/prompt/`;

  const groupPromptsInToCategories = (data) => {
    let categories = {};
    data.forEach((item) => {
      let items = categories[item.category] ?? [];
      items.push(item);
      categories[item.category] = items;
    });
    return categories;
  };

  const handlePromptDataSuccess = (data) => {
    const groupedPrompts = groupPromptsInToCategories(data);
    const categories = Object.keys(groupedPrompts);
    if (!categories.includes('All')) {
      categories.unshift('All');
    }
    dispatch(
      promptActions.promptsUpdated({
        ...prompts,
        prompts: groupedPrompts,
        categories: categories,
        loadedPrompts: data,
      })
    );
  };

  const loadPrompts = async () => {
    setLoading(true);
    const response = await api.get(endpoint);
    if (response?.data) {
      handlePromptDataSuccess(response?.data);
    } else {
      setError(response?.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!prompts?.prompts) {
      loadPrompts();
    }
  }, [prompts?.loadedPrompts]);

  return { loading, handlePromptDataSuccess, error };
}
