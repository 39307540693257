import useShareChat from '../../../hooks/share/useShareChat';
import { useParams } from 'react-router-dom';
import { marked } from 'marked';
import Loader from '../../loader';
import AIResponse from '../../chat-room/upper-area/ai-response';
import UserQuery from '../../chat-room/upper-area/user-query';

export default function Chat() {
  const params = useParams();

  const { loading, data, error } = useShareChat(params?.sessionId);

  let msgToDisplay = null;
  if (data?.results?.length > 0) {
    let messages = data?.results;
    messages = [...messages].reverse();

    msgToDisplay = messages?.map((msg) => {
      return {
        type: msg?.type,
        content: msg?.content ? marked.parse(msg?.content) : '',
        rawContent: msg?.content,
        share: true,
      };
    });
  }

  return (
    <div className="body-right-side light-grey-bg-dark-mode">
      <div className="upper-area">
        {loading && <Loader />}

        {msgToDisplay?.map((item, i) => {
          if (item?.type === 'ai') {
            return <AIResponse key={i} message={item} />;
          } else {
            return <UserQuery message={item} key={i} />;
          }
        })}
      </div>
    </div>
  );
}
