import Error403 from '../../errors/pages/error403';
import Loader from '../loader';
import { useEffect } from 'react';
import { redirectToAuth } from '../../utils/auth';

export default function LoggedOut() {
  useEffect(() => {
    redirectToAuth(true);
  }, []);
  return <Loader />;
}
