import { toast } from 'react-toastify';
import { api } from '@iblai/ibl-web-react-common';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useManageAccount() {
  const auth = useSelector((state) => state.auth.data);
  const [tenant, setTenant] = useState(auth.tenant);
  const [user, setUser] = useState(auth.user);
  const [submitting, setSubmitting] = useState(false);

  const handleTenantChange = (event) => {
    const { name, value } = event.target;
    setTenant((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const submitTenant = async () => {
    const data = { key: auth.tenant.key, name: tenant?.name };
    api.ibldmplatform.updateCorePlatformConfigSite(
      data,
      (data) => console.log(data),
      (error) => console.error(error)
    );
  };

  const submitUser = async () => {
    const data = {
      name: user.user_fullname,
      username: user.user_nicename,
    };

    api.ibledxusers.postUsersManageMetadata(
      data,
      (response) => {
        if (response.status_code === 200) {
          toast.success('User data has been updated');
        }
        setSubmitting(false);
      },
      (error) => {
        console.log(error);
        setSubmitting(false);
      }
    );
  };

  const submitAccountData = async () => {
    setSubmitting(true);
    await submitUser();
  };

  const handleUserChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => {
      return { ...prevState, [name]: value };
    });
    console.log('email', name, value);
  };

  return {
    handleUserChange,
    handleTenantChange,
    tenant,
    user,
    submitAccountData,
    submitting,
  };
}
