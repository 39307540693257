import { useState } from 'react';

export default function useDisclaimer() {
  const [hide, setHide] = useState(localStorage.getItem('IBL_HIDE_DISCLAIMER'));

  const handleDisclaimerHideBtnClick = () => {
    localStorage.setItem('IBL_HIDE_DISCLAIMER', 'true');
    setHide('true');
  };

  return { hide, handleDisclaimerHideBtnClick };
}
