import React from 'react';
import './Aidefaultdisclaimerbox.css';
import useDisclaimer from '../../hooks/navigation/useDisclaimer';

const Aidefaultdisclaimerbox = (props) => {
  const { hide, handleDisclaimerHideBtnClick } = useDisclaimer();
  const disclaimerText = process.env.REACT_APP_IBL_DISCLAIMER_TEXT;
  return (
    <>
      {!hide && (
        <div className="ai-response-container ai-disclaimer-container lighter-grey-bg-dark-mode ai-default-response-container">
          <div className="ai-gif-container">
            <img
              alt=""
              className="image-3 whiten-in-dark-mode disclaimer-img"
              loading="lazy"
              src="/images/info.svg"
            />
          </div>

          <div className="ai-response-text-container default-ai-response-text-container">
            <div className="text-block-5 whiten-in-dark-mode">
              {disclaimerText}
            </div>
            <div className="div-block-5">
              <div
                onClick={handleDisclaimerHideBtnClick}
                className="dont-show-again primary-color-in-dark-mode primary-bg-hover-in-dark-mode"
                prop-events-value-onclick="handleDisclaimerHideBtnClick"
              >
                Don't show again
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Aidefaultdisclaimerbox;
