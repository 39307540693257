import Llmbox from '../../components/Llmbox/Llmbox';
import Loader from '../loader';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useLLMs from '../../hooks/llm/useLLMs';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import { useDebounce } from '../../hooks/useDebounce';
import NoRecordFound from '../../components/NoRecordFound/NoRecordFound';

export default function LLMs({ showSelected }) {
  const mentors = useSelector((state) => state.mentors);
  const editMentor = useSelector((state) => state.mentors.edit);
  const { modals } = useSelector((state) => state.navigation.data);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [size, setSize] = useState(8);
  const [totalPages, setTotalPage] = useState(1);
  const [searchStarted, setSearchStarted] = useState(false);
  // Move the selected LLM to the top of list
  /*const selectedLLM = mentors?.llms?.find(
    (llm) => editMentor?.mentor?.llm_name === llm.model
  );*/
  const [llms, setLLMs] = useState([]);

  const [filteredLLMs, setFilteredLLMs] = useState([]);

  const { loading, error } = useLLMs();

  useEffect(() => {
    if (searchStarted) {
      //ESSENTIAL DUE TO VALID KEY ENDPOINT CALL RE_UPDATING THE CALLBACKS
      return;
    }
    let llmsList = mentors?.llms?.filter(
      (llm) => editMentor?.mentor?.llm_name !== llm.model
    );
    const selectedLLM = mentors?.llms?.find(
      (llm) => editMentor?.mentor?.llm_name === llm.model
    );
    if (selectedLLM) {
      llmsList.unshift(selectedLLM);
    }
    setFilteredLLMs(llmsList);
    setLLMs(llmsList);
  }, [mentors?.llms, editMentor?.mentor]);

  /*if (selectedLLM) {
    llms.unshift(selectedLLM);
  }*/

  const handleSearch = () => {
    if (String(search).length > 1) {
      setSearchStarted(true);
      setPage(1);
      setFilteredLLMs(
        llms.filter(
          (llm) =>
            String(llm?.model).toUpperCase().includes(search.toUpperCase()) ||
            String(llm?.name).toUpperCase().includes(search.toUpperCase())
        )
      );
    } else if (searchStarted) {
      setFilteredLLMs(llms);
    }
  };

  useDebounce(handleSearch, 300, [search]);

  return (
    <>
      <div className="w-layout-hflex search-box-block all-whiten-in-dark-mode">
        <img
          src="/images/search-1.svg"
          loading="lazy"
          alt=""
          className="image-49"
        />
        <div className="search-box-form-block w-form">
          <form
            id="email-form-4"
            name="email-form-4"
            data-name="Email Form 4"
            method="get"
            data-wf-page-id="650305782bc61751e5765214"
            data-wf-element-id="abb631e8-2d55-e362-153c-2079ad5affba"
          >
            <input
              className="text-field-4 w-input"
              autoComplete="on"
              maxLength="256"
              name="name-5"
              data-name="Name 5"
              placeholder="Search LLMs"
              type="text"
              id="name-5"
              value={search}
              onChange={(e) => setSearch(e?.target?.value)}
            />
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
      {loading ? (
        <IBLSpinLoader size={35} containerHeight={'150px'} />
      ) : filteredLLMs?.length > 0 ? (
        <>
          <div
            className={`w-layout-grid llm-grid-block ${modals?.['edit-mentor-modal'] ? 'edit-mentor-llm-grid-block' : ''} `}
          >
            {filteredLLMs?.map((llm) => (
              <Llmbox showSelected={showSelected} key={llm?.model} llm={llm} />
            ))}
          </div>
          {totalPages > 1 && (
            <div className="w-layout-hflex users-settings-pagination-block centered space-up">
              <div className="w-layout-hflex users-settings-pagination-container all-whiten-in-dark-mode">
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode">
                  <img
                    src="/images/chevron-first.svg"
                    loading="lazy"
                    alt=""
                    className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-inactive greyish-bg-in-darkmode">
                  <img
                    src="/images/chevron-left-2.svg"
                    loading="lazy"
                    alt=""
                    className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-active">
                  <h5 className="table-pagination-page-number primary-color-in-dark-mode">
                    1
                  </h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <h5 className="table-pagination-page-number">2</h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <h5 className="table-pagination-page-number">3</h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn table-pagination-btn-no-hover">
                  <img
                    src="/images/more-horizontal-3.svg"
                    loading="lazy"
                    alt=""
                    className="table-pagination-btn-icon table-pagination-btn-icon-disabled"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <h5 className="table-pagination-page-number">20</h5>
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <img
                    src="/images/chevron-right.svg"
                    loading="lazy"
                    alt=""
                    className="table-pagination-btn-icon"
                  />
                </div>
                <div className="w-layout-hflex table-pagination-btn greyish-bg-in-darkmode">
                  <img
                    src="/images/chevron-last-1.svg"
                    loading="lazy"
                    alt=""
                    className="table-pagination-btn-icon"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <NoRecordFound />
      )}
    </>
  );
}
