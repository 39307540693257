import React from "react";
import "./Deletechatmodalbox.css";


const Deletechatmodalbox = props => {
    
    return (
<div className="modal delete-history-modal">
<div className="w-layout-vflex modal-container create-secret-key-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">Delete Chat</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="/images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body create-secret-key-modal-body">
<div className="delete-chat-rich-text w-richtext">
<p>You'll no longer see this chat here. This will also delete related activity like prompts, responses, and feedback from your IBL AI Mentor Activity. <a href="http://www.ibleducation.com" rel="noopener noreferrer" target="_blank">Learn more</a>
</p>
</div>
<div className="w-layout-hflex flex-block-27">
<div className="w-layout-hflex flex-block-20">
<a className="default-btn form-submit-btn form-cancel-btn w-button" data-w-id="3e80a1fe-bae3-97a9-463e-07eaf04c1030" href="#">Cancel</a>
<a className="default-btn form-submit-btn history-modal-submit-btn w-button" data-w-id="3e80a1fe-bae3-97a9-463e-07eaf04c1032" href="#">Delete</a>
</div>
</div>
</div>
</div>
</div>
)}

export default Deletechatmodalbox;