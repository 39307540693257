import { useEffect, useState } from 'react';
import { isValidHttpUrl } from '../../../utils/shared';
import { useSelector } from 'react-redux';
import useApi from '../base/useApi';
import { BASE_API_URL } from '../../../utils/api';

export default function useMentorDataset() {
  const [datasets, setDatasets] = useState(null);
  const [search, setSearch] = useState('');
  const [uri, setUri] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

  const api = useApi();

  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const nav = useSelector((state) => state.navigation.data);

  const getDatasets = async () => {
    const url =
      uri ??
      `/api/ai-index/orgs/${auth?.tenant?.key}/users/${auth?.user?.user_nicename}/documents/pathways/${mentors?.edit?.mentor?.name}/`;
    setLoading(true);
    const response = await api.get(url);
    if (response?.data) {
      setDatasets(response?.data);
    }
    setHasLoadedOnce(true);
    setLoading(false);
  };

  const handleNextPage = () => {
    const uri = datasets?.next
      ?.replace('http://', 'https://')
      .replace(BASE_API_URL, '');
    setUri(uri);
  };

  const handlePrevPage = () => {
    const uri = datasets?.previous
      ?.replace('http://', 'https://')
      .replace(BASE_API_URL, '');
    setUri(uri);
  };

  const getDataSetType = (item) => {
    return isValidHttpUrl(item.url) ? 'URL' : 'TEXT';
  };
  let datasetsToRender = [];

  if (datasets?.results?.length) {
    datasetsToRender = datasets?.results.map((item) => {
      const fileType = item.document_type ?? getDataSetType(item);
      return {
        ...item,
        document_type: fileType,
      };
    });
  }

  console.log(datasetsToRender);

  if (search && datasetsToRender?.length) {
    datasetsToRender = datasetsToRender.filter((item) =>
      item?.document_name?.toLowerCase()?.includes(search?.toLowerCase())
    );
  }

  useEffect(() => {
    getDatasets();
  }, [mentors?.edit?.mentor, nav?.modals?.['dataset-url-resource-modal'], uri]);

  return {
    loading,
    datasetsToRender,
    search,
    setSearch,
    getDatasets,
    hasLoadedOnce,
    handlePrevPage,
    handleNextPage,
    datasets,
  };
}
