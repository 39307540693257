import React from "react";
import "./Billinghistorymodalbox.css";


const Billinghistorymodalbox = props => {
    
    return (
<div className="modal billing-history-modal">
<div className="w-layout-vflex modal-container billing-history-modal-container">
<div className="w-layout-hflex modal-header">
<h3 className="modal-header-title">Billing History</h3>
<div className="modal-close-wrapper" data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588" prop-events-value-onclick="handleModalCloseClick"><img alt="" loading="lazy" src="/images/close_1close.png"/></div>
</div>
<div className="w-layout-vflex modal-body billing-history-modal-body">
<div className="text-block-25">Showing invoices within the past 12 months</div>
<div className="w-layout-vflex table-container">
<div className="w-layout-hflex table-header">
<div className="invoice-block">
<h5 className="table-title">INVOICE</h5>
</div>
<div className="status-block">
<h5 className="table-title">STATUS</h5>
</div>
<div className="amount-block">
<h5 className="table-title">AMOUNT</h5>
</div>
<div className="created-at-block">
<h5 className="table-title">CREATED</h5>
</div>
<div className="action-block"></div>
</div>
<div className="w-layout-hflex table-body-row">
<div className="invoice-block">
<div className="table-text">BE678HKJKJK</div>
</div>
<div className="status-block">
<div className="div-block-34">
<div className="status-element">Paid</div>
</div>
</div>
<div className="amount-block">
<div className="table-text">$61.03</div>
</div>
<div className="created-at-block">
<div className="table-text">Jul 21, 2023 7:23 PM</div>
</div>
<div className="action-block">
<a className="link" href="#">View</a>
</div>
</div>
<div className="w-layout-hflex table-body-row">
<div className="invoice-block">
<div className="table-text">BE678HKJKJK</div>
</div>
<div className="status-block">
<div className="div-block-34">
<div className="status-element status-element-unpaid">Unpaid</div>
</div>
</div>
<div className="amount-block">
<div className="table-text">$33.90</div>
</div>
<div className="created-at-block">
<div className="table-text">Jul 17, 2023 5:45 PM</div>
</div>
<div className="action-block">
<a className="link" href="#">View</a>
</div>
</div>
<div className="w-layout-hflex table-body-row">
<div className="invoice-block">
<div className="table-text">BE678HKJKJK</div>
</div>
<div className="status-block">
<div className="div-block-34">
<div className="status-element">Paid</div>
</div>
</div>
<div className="amount-block">
<div className="table-text">$123.55</div>
</div>
<div className="created-at-block">
<div className="table-text">Jul 05, 2023 1:45 AM</div>
</div>
<div className="action-block">
<a className="link" href="#">View</a>
</div>
</div>
</div>
</div>
</div>
</div>
)}

export default Billinghistorymodalbox;