import React from 'react';
import errorImg from '../../assets/images/404-error.png';
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-errors.webflow.css';
import '../../assets/css/custom.css';

import { Link } from 'react-router-dom';

const ErrorNoMentors = ({ redirect }) => {
  // }, []);

  return (
    <div className="w-layout-hflex-error-ui error-page-main-container">
      <div className="w-layout-hflex-error-ui error-page-layout">
        <div className="w-layout-hflex-error-ui error-page-left-side">
          <div className="w-layout-hflex-error-ui error-page-image-block">
            <img
              src={errorImg}
              loading="lazy"
              alt=""
              className="error-page-image"
            />
          </div>
        </div>
        <div className="w-layout-hflex-error-ui error-page-right-side">
          <h1 className="error-page-primary-heading">Error</h1>
          <h1 className="error-page-secondary-heading">
            No mentors available for you.
          </h1>
          <div className="error-page-description">
            You currently don't have any mentors to chat with
            <br />
            Please reach out to your admin
          </div>
          <div className="w-layout-hflex-error-ui error-page-btn">
            <Link to="/" className={'error-page-btn-link w-inline-block'}>
              <div className="error-page-btn-label">Reload</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorNoMentors;
