import { useSelector } from 'react-redux';
import { mentorIsIframe } from '../../utils/shared';

export function useHeader() {
  const auth = useSelector((state) => state.auth.data);
  const mentors = useSelector((state) => state.mentors);
  const isMentorIframed = mentorIsIframe();

  let headerLogoTitle =
    (isMentorIframed
      ? process.env.REACT_APP_IBL_SPECIAL_LOGO_TITLE_WHEN_IFRAMED
      : process.env.REACT_APP_IBL_HEADER_LOGO_TITLE) ?? '';

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('embed') === 'true') {
    headerLogoTitle = mentors?.mentor?.name;
  }

  const PROMPT_GALLERY_ENABLED =
    process.env.REACT_APP_IBL_ENABLE_PROMPT_GALLERY === 'true';
  const USE_SPECIAL_IFRAME_LOGO =
    process.env.REACT_APP_IBL_ENABLE_SPECIAL_LOGO_WHEN_IFRAMED === 'true' &&
    isMentorIframed;
  const logoSize = !isMentorIframed
    ? 'auto' //process.env.REACT_APP_IBL_LOGO_SIZE
    : 'unset'; //process.env.REACT_APP_IBL_SPECIAL_LOGO_WHEN_IFRAMED_SIZE) ?? '100%';

  let profileImageUrl = mentors?.mentor?.settings?.profile_image;
  if (profileImageUrl && !profileImageUrl.includes('s3.amazonaws.com')) {
    profileImageUrl += `?username=${auth?.user?.user_nicename}&tenant=${auth?.tenant?.key}`;
  }

  const HELP_THREE_DOT_MENU_ENABLED =
    process.env.REACT_APP_IBL_ENABLE_MENTOR_HELP_MENUS === 'true';

  return {
    headerLogoTitle,
    PROMPT_GALLERY_ENABLED,
    USE_SPECIAL_IFRAME_LOGO,
    logoSize,
    profileImageUrl,
    HELP_THREE_DOT_MENU_ENABLED,
  };
}
