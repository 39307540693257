import { createSlice } from '@reduxjs/toolkit';

export const usersInitialState = {
  users: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    reset: () => usersInitialState,
    usersUpdated: (state, action) => {
      state.users = action.payload;
    },
  },
});

export const usersActions = usersSlice.actions;
export default usersSlice.reducer;
