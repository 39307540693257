import React from 'react';
import './Datasetyoutuberesourceuploadmodalbox.css';
import useDatasetResourceAdd from '../../hooks/datasets/useDatasetResourceAdd';
import SubmitBtn from '../SubmitBtn/SubmitBtn';

const Datasetyoutuberesourceuploadmodalbox = (props) => {
  return (
    <div className="modal dataset-youtube-resource-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container dataset-url-resource-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">YouTube Resource Upload</h3>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="1860f14d-8ea7-daca-eba5-62232122c044"
              data-wf-page-id="650305782bc61751e5765214"
              id="email-form-2"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    YouTube URL
                  </label>
                </div>
                <input
                  className="form-input secret-key-form-input w-input"
                  custom-value="Personal"
                  data-name="Organisation Name 4"
                  id="organisation-name-4"
                  maxLength={256}
                  name="organisation-name-4"
                  placeholder="https://www.youtube.com"
                  type="text"
                />
              </div>
              <div className="w-layout-hflex flex-block-20">
                <SubmitBtn
                  data-w-id="1860f14d-8ea7-daca-eba5-62232122c04b"
                  href="#"
                  label={'Submit'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datasetyoutuberesourceuploadmodalbox;
