import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@iblai/ibl-web-react-common';
import { usersActions } from '../../lib/redux/users/slice';

export default function useUsers() {
  const auth = useSelector((state) => state.auth.data);
  const [params, setParams] = useState({ key: auth?.tenant?.key });
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [userOnRequest, setUserOnRequest] = useState(null);

  const users = useSelector((state) => state.users.users);

  const dispatch = useDispatch();

  const handleFetchUsersSuccess = (data) => {
    dispatch(usersActions.usersUpdated(data?.body));
    setLoading(false);
  };

  const handleFetchUsersError = (error) => {
    setLoading(false);
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleUpdateUserStatusSuccess = (data) => {
    removeUserRecord(userOnRequest);
  };

  const handleUserRoleUpdateSuccess = (data) => {};

  const handleActiveStatusToggle = (user) => {
    setUserOnRequest(user);
    const data = {
      username: user.username,
      active: !user.active,
      key: auth?.tenant?.key,
    };
    api.ibldmplatform.createCoreUsersPlatforms(
      data,
      handleUpdateUserStatusSuccess,
      (error) => console.log(error)
    );
  };

  const handleUpdateRole = (user, role) => {
    const isAdmin = role === 'admin';
    setUserOnRequest({ ...userOnRequest, is_admin: isAdmin });
    const data = {
      username: user.username,
      is_admin: isAdmin,
      key: auth?.tenant?.key,
    };
    api.ibldmplatform.createCoreUsersPlatforms(
      data,
      handleUserRoleUpdateSuccess,
      (error) => console.log(error)
    );
  };

  const updateUserRecord = (user) => {};

  const removeUserRecord = (user) => {};

  const handleSetPage = (page) => {
    setParams((prevState) => {
      return { ...prevState, page };
    });
  };

  const fetchUsers = () => {
    setLoading(true);
    api.ibldmplatform.getCorePlatformUsers(
      params,
      handleFetchUsersSuccess,
      handleFetchUsersError
    );
  };

  useEffect(() => {
    if (users && params?.query) {
      fetchUsers();
    }
  }, [params]);

  useEffect(() => {
    if (users === null) {
      fetchUsers();
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setParams((prevState) => {
        return { ...prevState, query };
      });
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query, 500]);

  return {
    users,
    loading,
    handleSetPage,
    updateUserRecord,
    handleChange,
    handleActiveStatusToggle,
  };
}
