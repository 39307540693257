import Promptgallerycategory from '../Promptgallerycategory/Promptgallerycategory';
import Promptbox from '../Promptbox/Promptbox';
import Promptgallerycontentsbox from '../Promptgallerycontentsbox/Promptgallerycontentsbox';
import React from 'react';
import './Promptgallery.css';
import { useTriggers } from '../../hooks/navigation';
import usePrompts from '../../hooks/api/prompts/usePrompts';
import { useSelector } from 'react-redux';
import Loader from '../../modules/loader';

const Promptgallery = (props) => {
  const { handleModalCloseClick, handleAddNewPromptBtnClick } = useTriggers();
  const prompts = useSelector((state) => state.prompts.data);
  const { loading, error } = usePrompts();
  return (
    <div className="modal prompt-gallery-modal">
      <div className="w-layout-vflex modal-container prompt-gallery-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">Prompts Gallery</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            + Add New Prompt
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>

        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
          <div className="w-layout-hflex prompt-category-block" no-mapping="">
            {prompts?.categories?.map((item) => (
              <Promptgallerycategory key={item} title={item} />
            ))}
          </div>

          {loading && <Loader />}

          {Object.keys(prompts?.prompts ?? {})?.map((item) => {
            if (
              item !== prompts.activeCategory &&
              prompts.activeCategory !== 'All'
            ) {
              return <span key={item}></span>;
            }
            return (
              <Promptgallerycontentsbox
                key={item}
                category={item}
                prompts={prompts?.prompts?.[item]}
              />
            );
          })}

          {!prompts?.loadedPrompts?.length > 0 && (
            <>
              <br />
              <div className="w-layout-grid prompt-gallery-grid">
                <div
                  className="w-layout-vflex prompt-grid-element add-prompt-grid-element light-grey-bg-dark-mode greyish-bg-in-darkmode add-prompt-grid-element-1"
                  data-w-id="b44be262-c2f3-c32d-452e-d05fbfdb71ec"
                  id="w-node-b44be262-c2f3-c32d-452e-d05fbfdb71ec-e5765214"
                  style={{ padding: '20px 0px 20px 0' }}
                  onClick={handleAddNewPromptBtnClick}
                >
                  <div className="w-layout-hflex flex-block-18">
                    <img
                      alt=""
                      className="image-28"
                      loading="lazy"
                      src="/images/plus-4.svg"
                    />
                  </div>
                  <h5 className="add-new-prompt-label">Add New Prompt</h5>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Promptgallery;
