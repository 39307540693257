import React from 'react';
import errorImg from '../../assets/images/404-error.png';
import '../../assets/css/normalize.css';
import '../../assets/css/webflow.css';
import '../../assets/css/ibl-default-errors.webflow.css';
import '../../assets/css/custom.css';
import { Link } from 'react-router-dom';

const Error403 = ({redirect}) => {

    return (
        <div className="w-layout-hflex-error-ui error-page-main-container">
            <div className="w-layout-hflex-error-ui error-page-layout">
                <div className="w-layout-hflex-error-ui error-page-left-side">
                    <div className="w-layout-hflex-error-ui error-page-image-block"><img src={errorImg} loading="lazy" alt="" className="error-page-image"/></div>
                </div>
                <div className="w-layout-hflex-error-ui error-page-right-side">
                    <h1 className="error-page-primary-heading">403</h1>
                    <h1 className="error-page-secondary-heading">Unauthorized page</h1>
                    <div className="error-page-description">We&#x27;re sorry, you&#x27;re trying to access a page you&#x27;re not authorized to. <br/>Please go back to the home page.</div>
                    <div className="w-layout-hflex-error-ui error-page-btn">
                        <Link to={redirect} className={"error-page-btn-link w-inline-block"}>
                            <div className="error-page-btn-label">Back to Homepage</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Error403