import Pinnedchatbox from '../Pinnedchatbox/Pinnedchatbox';
import Recentchatbox from '../Recentchatbox/Recentchatbox';
import React from 'react';
import './Sidebar.css';
import { useSelector } from 'react-redux';
import useRecentMessages from '../../hooks/api/messages/useRecentMessages';
import usePinnedMessages from '../../hooks/api/messages/usePinnedMessages';
import useNewChat from '../../hooks/chat-room/useNewChat';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Sidebar = (props) => {
  const { recentMessages, pinMessages, moreRecentMessages } = useSelector(
    (state) => state.chat
  );
  const { handleShowMoreChatBoxBtnClick, moreMessagesLoading } =
    useRecentMessages();
  const pmessages = usePinnedMessages();

  const pmessagesToDisplay = pinMessages?.results?.filter(
    (item) => item?.messages?.length > 0
  );
  const rmessagesToDisplay = recentMessages?.results?.filter(
    (item) => item?.messages?.length > 0
  );

  const { handleNewChatBtnClick } = useNewChat();
  const { sidebar, mobileSidebar } = useSelector(
    (state) => state.navigation.data
  );

  return (
    <div
      className={`body-left-side all-whiten-in-dark-mode`}
      style={{ display: sidebar || mobileSidebar ? 'flex' : 'none' }}
    >
      <div className="w-layout-vflex body-left-side-block">
        <div
          className="w-layout-hflex new-chat-btn-block all-whiten-in-dark-mode greyish-bg-in-darkmode light-grey-bg-dark-mode"
          onClick={handleNewChatBtnClick}
          behave-as-btn="1"
          prop-events-names="onClick"
          prop-events-value-onclick="handleNewChatBtnClick"
          title="New Chat"
          tabIndex="101"
          role="button"
        >
          <img
            alt=""
            className="image-32"
            loading="lazy"
            src="/images/plus-4.svg"
          />
          <div className="text-block-32">New chat</div>
        </div>
        <div className="w-layout-vflex history-container">
          {pmessagesToDisplay?.length > 0 && (
            <div className="w-layout-vflex history-set-container">
              <h6 className="heading-8">Pinned</h6>
              {pmessagesToDisplay.map((item) => (
                <Pinnedchatbox key={item.session_id} item={item} />
              ))}
            </div>
          )}
          {rmessagesToDisplay?.length > 0 && (
            <div className="w-layout-vflex history-set-container">
              <h6 className="heading-8">Recent</h6>
              {rmessagesToDisplay?.map((item) => (
                <Recentchatbox key={item.session_id} item={item} />
              ))}
            </div>
          )}

          <div className="w-layout-vflex history-set-container">
            {recentMessages?.next && (
              <div
                className="w-layout-hflex show-more-less-block show-more-btn greyish-bg-in-darkmode"
                data-w-id="121c52ef-943c-710b-2870-9f612f67ac1d"
                onClick={handleShowMoreChatBoxBtnClick}
                prop-events-value-onclick="handleShowMoreChatBoxBtnClick"
              >
                <img
                  alt=""
                  className="image-38"
                  loading="lazy"
                  src="/images/chevron-down.svg"
                />
                <div>Show more</div>
              </div>
            )}

            {moreRecentMessages?.map((item) => (
              <Recentchatbox key={item.session_id} item={item} />
            ))}

            {/*<div
                className="w-layout-hflex show-more-less-block show-less-btn greyish-bg-in-darkmode"
                data-w-id="c74be5ee-9a6b-d55d-bc4f-a9ba9cdcd8c3"
              >
                <img
                  alt=""
                  className="image-38"
                  loading="lazy"
                  src="/images/chevron-up.svg"
                />
                <div>Show less</div>
              </div>*/}
          </div>
          {moreMessagesLoading && <IBLSpinLoader size={35} />}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
