import React from 'react';
import './Dataseturlresourceuploadmodalbox.css';
import useDatasetResourceAdd from '../../hooks/datasets/useDatasetResourceAdd';
import { useTriggers } from '../../hooks/navigation';
import {
  getDatasetFormatExtensions,
  isValidUrl,
  userIsOnTrial,
} from '../../utils/shared';
import SubmitBtn from '../SubmitBtn/SubmitBtn';
import { toast } from 'react-toastify';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';

const Dataseturlresourceuploadmodalbox = (props) => {
  const {
    dataset,
    handleSubmit,
    handleUrlChange,
    handleBranchChange,
    handleFileChange,
    submitting,
    url,
  } = useDatasetResourceAdd();
  const { handleModalCloseClick } = useTriggers();
  const [branches, setBranches] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const debounce = (func, delay) => {
  let debounceTimer;
  return (...args) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
};

  const fetchBranches = async (repoUrl) => {
  try {
    const repoPath = repoUrl.replace('https://github.com/', '').replace('.git', '');
    const response = await fetch(`https://api.github.com/repos/${repoPath}/branches`);

    if (!response.ok) {
      throw new Error('Failed to fetch branches');
    }

    const branches = await response.json();
    return branches.map(branch => branch.name);
  } catch (error) {
    console.error('Error fetching branches:', error);
    setError(error.message);
    return [];
  }
};

  const debouncedFetchBranches = debounce(async (url) => {
    setError('');
    setLoading(true);
    const fetchedBranches = await fetchBranches(url);
    setBranches(fetchedBranches);
    setLoading(false);

  }, 300);

  React.useEffect(() => {
    if (dataset?.type === 'GitHub' && url) {
        debouncedFetchBranches(url)
    }
  }, [dataset?.type, url]);
  const getFormElements = (type) => {
    if (type === 'GitHub') {
    return (
      <div className={"git-form"}>
        <input
          className="form-input secret-key-form-input w-input"
          custom-value="Personal"
          data-name="url"
          disabled={submitting}
          id="dataset-url"
          maxLength={256}
          name=""
          onChange={handleUrlChange}
          placeholder="Github Repo URL"
          type="text"
        />
        {error && <p className="w-file-upload-error-msg error-message">{error + " make sure this is a public repo"}</p>}
        {loading ? <IBLSpinLoader size={20} color={'#788'} /> :
          <select
            className="form-input secret-key-form-input w-input"
            name="branch"
            disabled={submitting}
            onChange={handleBranchChange}
          >
            <option value="">Select Branch</option>
            {branches.map(branch => (
              <option key={branch} value={branch}>
                {branch}
              </option>
            ))}
          </select>
        }
      </div>
    );
  } else if (['URL', 'YouTube'].includes(type)) {
    return (
      <input
        className="form-input secret-key-form-input w-input"
        custom-value="Personal"
        data-name="url"
        disabled={submitting}
        id="dataset-url"
        maxLength={256}
        name=""
        onChange={handleUrlChange}
        placeholder="URL"
        type="text"
      />
    );
  } else {
    return (
      <input
        onChange={handleFileChange}
        type="file"
        name="file"
        disabled={submitting}
        id="dataset-file-input"
        accept={getDatasetFormatExtensions?.[type]?.join(',')}
      />
    );
  }
};

  return (
    <div className="modal dataset-url-resource-modal">
      <div className="w-layout-vflex modal-container create-secret-key-modal-container dataset-url-resource-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{dataset?.type}</h3>
          <div
            className="modal-close-wrapper"
            onClick={handleModalCloseClick}
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body create-secret-key-modal-body">
          <div className="secret-key-form-block w-form">
            <form
              className="manage-account-form secret-key-form"
              data-name="Email Form 2"
              data-wf-element-id="80a8acea-99b7-6d2f-d101-79f95257134c"
              data-wf-page-id="650305782bc61751e5765214"
              id="dataset-document-form"
              method="get"
              name="email-form-2"
            >
              <div className="w-layout-vflex input-block invite-user-secret-block">
                <div className="w-layout-hflex flex-block-19">
                  <label className="form-input-label" htmlFor="name-6">
                    {dataset?.type}
                  </label>
                </div>
                {getFormElements(dataset?.type)}
              </div>
              <div className="w-layout-hflex flex-block-20">
                <SubmitBtn
                  onClick={
                    !submitting
                      ? (e) => {
                          if (
                            ['URL', 'YouTube'].includes(dataset?.type) &&
                            !isValidUrl(url)
                          ) {
                            toast.warning('Please submit a valid URL.');
                            return;
                          }
                          if (
                            dataset?.type === 'YouTube' &&
                            !url.includes('youtube.com')
                          ) {
                            toast.warning('Please submit a valid Youtube URL.');
                            return;
                          }
                          handleSubmit(e);
                        }
                      : () => {}
                  }
                  data-w-id="80a8acea-99b7-6d2f-d101-79f952571355"
                  href="#"
                  saving={submitting}
                  label={'Submit'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dataseturlresourceuploadmodalbox;
