import Aidefaultreponsebox from '../../../components/Aidefaultreponsebox/Aidefaultreponsebox';
import Aidefaultdisclaimerbox from '../../../components/Aidefaultdisclaimerbox/Aidefaultdisclaimerbox';
import Userpromptbox from '../../../components/Userpromptbox/Userpromptbox';

import React from 'react';
import { useSelector } from 'react-redux';
import UserQuery from './user-query';
import AIResponse from './ai-response';
import GeneratingResponse from './generating-response';
import GuidedSuggestedPrompts from '../bottom-area/guided-suggested-prompts';

export default function ChatMessages({ generating, streaming }) {
  const chat = useSelector((state) => state.chat.data);
  const mentors = useSelector((state) => state.mentors);
  const allMessages = useSelector((state) => state.chat.messages);
  return (
    <div className="upper-area">
      {!allMessages?.length && !generating && !chat.generating && (
        <>
          <Aidefaultreponsebox />

          {process.env.REACT_APP_IBL_ENABLE_CHATROOM_DISCLAIMER === 'true' && (
            <Aidefaultdisclaimerbox />
          )}
        </>
      )}

      {allMessages?.map((message, i) => {
        if (message?.type === 'human') {
          const proActivePrompt = mentors?.mentor?.proactive_prompt;
          if (
            message?.hide ||
            (proActivePrompt && message?.content === proActivePrompt) ||
            (message?.rawContent && proActivePrompt === message?.rawContent)
          ) {
            return '';
          }
          return <UserQuery message={message} key={`${i}-msg`} />;
        } else {
          return (
            <AIResponse
              userQuery={allMessages?.[i - 1]}
              message={message}
              streaming={streaming}
              key={`${i}-msg`}
              index={i}
            />
          );
        }
      })}

      {(generating || chat?.generating) && <GeneratingResponse />}

      {!generating && !streaming && <GuidedSuggestedPrompts />}

      <div className="scroll-bottom-element"></div>
      <div className="templates-block">
        <div className="audio-prompt-template">
          <div className="user-prompt-text-container user-file-prompt-container">
            <div className="user-prompt-text-block audio-block">
              <div className="w-layout-hflex file-prompt-block audio-prompt-block">
                <div className="w-layout-hflex file-prompt-icon-block audio-prompt-icon-block">
                  <img
                    alt=""
                    className="file-prompt-icon audio-prompt-icon play"
                    loading="lazy"
                    src="/images/play-1000-svgrepo-com.svg"
                  />
                  <img
                    alt=""
                    className="file-prompt-icon audio-prompt-icon hidden pause"
                    loading="lazy"
                    src="/images/pause-1006-svgrepo-com.svg"
                  />
                </div>
                <div className="w-layout-hflex audio-prompt-wave-container">
                  <img
                    alt=""
                    className="audio-prompt-wave-img"
                    loading="lazy"
                    sizes="100vw"
                    src="/images/colorful-sound-wave-equalizer-2.png"
                    srcSet="images/colorful-sound-wave-equalizer-2-p-500.png 500w, images/colorful-sound-wave-equalizer-2-p-800.png 800w, images/colorful-sound-wave-equalizer-2-p-1080.png 1080w, images/colorful-sound-wave-equalizer-2-p-1600.png 1600w, images/colorful-sound-wave-equalizer-2-p-2000.png 2000w, images/colorful-sound-wave-equalizer-2.png 2500w"
                  />
                </div>
                <div className="w-layout-hflex audio-prompt-dot-seperator"></div>
                <div className="audio-prompt-timer">0:13</div>
              </div>
            </div>
          </div>
        </div>
        <div className="file-prompt-template">
          <div className="user-prompt-text-container user-file-prompt-container">
            <div className="user-prompt-text-block file-block">
              <div className="w-layout-hflex file-prompt-block">
                <div className="w-layout-hflex file-prompt-icon-block">
                  <img
                    alt=""
                    className="file-prompt-icon"
                    loading="lazy"
                    src="/images/file-text-1.svg"
                  />
                </div>
                <div className="w-layout-vflex file-prompt-uploaded-info-block">
                  <h6 className="file-upload-name-block uploaded">
                    some-file-name-dot-extension
                  </h6>
                  <div className="file-upload-extension-block uploaded">
                    PDF
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="user-prompt-template">
          <Userpromptbox />
        </div>
        <div className="prepend-actions-template"></div>
        <div className="append-actions-template">
          <div className="w-layout-vflex learn-more-block">
            <div className="w-layout-hflex learn-more-container all-whiten-in-dark-mode">
              <h5 className="heading-10">Learn more :</h5>
              <a
                className="learn-more-link w-inline-block"
                href="https://www.google.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="text-block-37">google.com</div>
              </a>
              <a
                className="learn-more-link w-inline-block"
                href="https://www.wikipedia.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="text-block-37">en.wikipedia.com</div>
              </a>
              <a
                className="learn-more-link w-inline-block"
                href="https://rosarynetwork.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="text-block-37">rosary.network</div>
              </a>
            </div>
            <div className="w-layout-hflex rating-copy-retry-block">
              <div className="w-layout-hflex ai-response-rating-block all-whiten-in-dark-mode">
                <div
                  className="w-layout-hflex ai-response-rating-thumb greyish-bg-in-darkmode"
                  data-w-id="cf4e2adb-c73d-8df6-6b6c-231baa6db65b"
                >
                  <img
                    alt=""
                    className="ai-response-rating-thumb-icon inactive"
                    loading="lazy"
                    src="/images/thumbs-up.svg"
                  />
                  <img
                    alt=""
                    className="ai-response-rating-thumb-icon active"
                    loading="lazy"
                    src="/images/thumbs-up-1.svg"
                  />
                  <div className="tooltip tooltip-rating-thumbs">
                    Good response
                  </div>
                </div>
                <div
                  className="w-layout-hflex ai-response-rating-thumb greyish-bg-in-darkmode"
                  data-w-id="cf4e2adb-c73d-8df6-6b6c-231baa6db660"
                >
                  <img
                    alt=""
                    className="ai-response-rating-thumb-icon inactive"
                    loading="lazy"
                    src="/images/thumbs-down.svg"
                  />
                  <img
                    alt=""
                    className="ai-response-rating-thumb-icon active"
                    loading="lazy"
                    src="/images/thumbs-down-1.svg"
                  />
                  <div className="tooltip tooltip-rating-thumbs">
                    Bad response
                  </div>
                </div>
              </div>
              <div className="w-layout-hflex copy-retry-container all-whiten-in-dark-mode">
                <a
                  className="response-action-link copy-action-btn w-inline-block"
                  href="#"
                >
                  <img
                    alt=""
                    className="response-action-icon"
                    loading="lazy"
                    src="/images/clipboard.svg"
                  />
                  <div className="response-action-label">Copy</div>
                </a>
                <a
                  className="response-action-link retry-action-btn w-inline-block"
                  href="#"
                >
                  <img
                    alt=""
                    className="response-action-icon"
                    loading="lazy"
                    src="/images/rotate-ccw.svg"
                  />
                  <div className="response-action-label">Retry</div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="empty-response-template">
          <div className="ai-response-container ai-prompt-response-container light-black-bg-dark-mode learn-more-container">
            <div className="w-layout-hflex ai-response-container-content">
              <div className="ai-gif-container">
                <img
                  alt=""
                  className="image-3"
                  loading="lazy"
                  src="/images/ibl-gpt-ai.gif"
                />
              </div>
              <div
                className="ai-response-text-container"
                id="ai_mock_response_text_container"
              ></div>
            </div>
            <div className="w-layout-vflex learn-more-block is-hidden">
              <div className="w-layout-hflex learn-more-container all-whiten-in-dark-mode">
                <h5 className="heading-10">Learn more :</h5>
                <a
                  className="learn-more-link w-inline-block"
                  href="https://www.google.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="text-block-37">google.com</div>
                </a>
                <a
                  className="learn-more-link w-inline-block"
                  href="https://www.wikipedia.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="text-block-37">en.wikipedia.com</div>
                </a>
                <a
                  className="learn-more-link w-inline-block"
                  href="https://rosarynetwork.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="text-block-37">rosary.network</div>
                </a>
              </div>
              <div className="w-layout-hflex rating-copy-retry-block">
                <div className="w-layout-hflex ai-response-rating-block all-whiten-in-dark-mode">
                  <div
                    className="w-layout-hflex ai-response-rating-thumb greyish-bg-in-darkmode"
                    data-w-id="c4318f0e-d25d-aadb-2579-f07e0ec21ddd"
                  >
                    <img
                      alt=""
                      className="ai-response-rating-thumb-icon inactive"
                      loading="lazy"
                      src="/images/thumbs-up.svg"
                    />
                    <img
                      alt=""
                      className="ai-response-rating-thumb-icon active"
                      loading="lazy"
                      src="/images/thumbs-up-1.svg"
                    />
                    <div className="tooltip tooltip-rating-thumbs">
                      Good response
                    </div>
                  </div>
                  <div
                    className="w-layout-hflex ai-response-rating-thumb greyish-bg-in-darkmode"
                    data-w-id="c4318f0e-d25d-aadb-2579-f07e0ec21de2"
                  >
                    <img
                      alt=""
                      className="ai-response-rating-thumb-icon inactive"
                      loading="lazy"
                      src="/images/thumbs-down.svg"
                    />
                    <img
                      alt=""
                      className="ai-response-rating-thumb-icon active"
                      loading="lazy"
                      src="/images/thumbs-down-1.svg"
                    />
                    <div className="tooltip tooltip-rating-thumbs">
                      Bad response
                    </div>
                  </div>
                </div>
                <div className="w-layout-hflex copy-retry-container all-whiten-in-dark-mode">
                  <a
                    className="response-action-link copy-action-btn w-inline-block"
                    href="#"
                  >
                    <img
                      alt=""
                      className="response-action-icon"
                      loading="lazy"
                      src="/images/clipboard.svg"
                    />
                    <div className="response-action-label">Copy</div>
                  </a>
                  <a
                    className="response-action-link retry-action-btn w-inline-block"
                    href="#"
                  >
                    <img
                      alt=""
                      className="response-action-icon"
                      loading="lazy"
                      src="/images/rotate-ccw.svg"
                    />
                    <div className="response-action-label">Retry</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="response-templates">
          <div className="list-template">
            <div className="ai-response-container ai-prompt-response-container">
              <div className="ai-gif-container">
                <img
                  alt=""
                  className="image-3"
                  loading="lazy"
                  src="/images/ibl-gpt-ai.gif"
                />
              </div>
              <div className="ai-response-text-container">
                <div className="ai-rich-text-zone w-richtext">
                  <p className="warning-parag">
                    <em>
                      Hey, this is just static text as part of a visual demo but
                      not connected to any LLMs!
                    </em>
                  </p>
                  <h4>Unordered List Template</h4>
                  <p>
                    Smartwatches are becoming increasingly popular, as they
                    offer a variety of features that can make our lives easier
                    and more convenient. However, there are also some
                    disadvantages to consider before buying a smartwatch.
                  </p>
                  <p>Advantages of smartwatches:</p>
                  <ul role="list">
                    <li>
                      <strong>Convenience:</strong> Smartwatches allow you to
                      stay connected without having to take your phone out of
                      your pocket. You can receive notifications, make and
                      answer calls, and even control music playback.
                    </li>
                    <li>
                      <strong>Convenience:</strong> Smartwatches allow you to
                      stay connected without having to take your phone out of
                      your pocket. You can receive notifications, make and
                      answer calls, and even control music playback.
                    </li>
                  </ul>
                  <p>Disadvantages of smartwatches:</p>
                  <ul role="list">
                    <li>
                      <strong>Convenience:</strong> Smartwatches allow you to
                      stay connected without having to take your phone out of
                      your pocket.
                    </li>
                    <li>
                      <strong>Convenience:</strong> Smartwatches allow you to
                      stay connected without having to take your phone out of
                      your pocket.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="markdown-template">
            <div className="ai-response-container ai-prompt-response-container">
              <div className="ai-gif-container">
                <img
                  alt=""
                  className="image-3"
                  loading="lazy"
                  src="/images/ibl-gpt-ai.gif"
                />
              </div>
              <div className="ai-response-text-container">
                <div className="w-embed">
                  <div className="markdown-template-parag">
                    # Markdown Template! ## This is a sub-heading... ### And
                    here's some other cool stuff: Heres some code, `<div></div>
                    `, between 2 backticks. ```javascript // this is multi-line
                    code: function anotherExample(firstLine, lastLine) {'{'}
                    if (firstLine == '```' &amp;&amp; lastLine == '```') {'{'}
                    return multiLineCode;
                    {'}'}
                    {'}'}
                    ``` ibl_student_data_tool You can also make text **bold**...
                    whoa! Or _italic_. Or... wait for it... **_both!_** And feel
                    free to go crazy ~~crossing stuff out~~. There's also
                    [links](https://www.freecodecamp.com), and &gt; Block
                    Quotes! And if you want to get really crazy, even tables:
                    Wild Header | Crazy Header | Another Header? ------------ |
                    ------------ | ------------- Your content can | be here, and
                    it | can be here.... And here. | Okay. | I think we get it.
                    - And of course there are lists. - Some are bulleted. - With
                    different indentation levels. - That look like this. 1. And
                    there are numbererd lists too. 1. Use just 1s if you want!
                    1. But the list goes on... - Even if you use dashes or
                    asterisks. * And last but not least, let's not forget
                    embedded images: ![React Logo w/
                    Text](/custom-assets/img/ibl-ai.png)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="citation-template">
            <div className="ai-response-container ai-prompt-response-container">
              <div className="ai-gif-container">
                <img
                  alt=""
                  className="image-3"
                  loading="lazy"
                  src="/images/ibl-gpt-ai.gif"
                />
              </div>
              <div className="ai-response-text-container">
                <div className="ai-rich-text-zone w-richtext">
                  <p className="warning-parag">
                    <em>
                      Hey, this is just static text as part of a visual demo but
                      not connected to any LLMs!
                    </em>
                  </p>
                  <h4>Citation Templates</h4>
                  <p>
                    The solemnity of Saint's Peter and Paul is a liturgical
                    feast in honor of the martyrdom in Rome of the apostles
                    Saint Peter and Saint Paul, which is observed on{' '}
                    <strong>
                      June 29th <sup>1</sup>
                    </strong>{' '}
                    This solemnity is a celebration of the martyrdom of both
                    saints and their leadership in the early{' '}
                    <strong>
                      church <sup>2</sup>{' '}
                    </strong>
                    . The date selected being the anniversary of either their
                    death or the translation of their{' '}
                    <strong>
                      relics <sup>3</sup>
                    </strong>
                    <br />
                  </p>
                </div>
                <div className="w-layout-hflex citation-list-container">
                  <div className="text-block-31 whiten-in-dark-mode">
                    Citations :
                  </div>
                  <a className="citation-element" href="#">
                    1. en.wikipedia.com
                  </a>
                  <a className="citation-element" href="#">
                    2. vatican.va
                  </a>
                  <a className="citation-element" href="#">
                    3. google.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="guided-question-template">
          <div className="w-layout-vflex guided-question-block">
            <div className="w-layout-hflex suggestions-prompt-container">
              <img
                alt=""
                className="image-4"
                loading="lazy"
                src="/images/question-primary.svg"
              />
              <div className="w-layout-hflex suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                <div>Generate study materials</div>
                <img
                  alt=""
                  className="image-39"
                  loading="lazy"
                  src="/images/arrow-sm-right-svgrepo-com.svg"
                />
              </div>
              <div className="w-layout-hflex suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                <div>Give me a lesson plan for this course</div>
                <img
                  alt=""
                  className="image-39"
                  loading="lazy"
                  src="/images/arrow-sm-right-svgrepo-com.svg"
                />
              </div>
              <div className="w-layout-hflex suggestion-prompt-element black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
                <div>Improve my understanding on this topic</div>
                <img
                  alt=""
                  className="image-39"
                  loading="lazy"
                  src="/images/arrow-sm-right-svgrepo-com.svg"
                />
              </div>
            </div>
            <div className="w-layout-vflex suggestion-reload-block black-bg-dark-mode primary-color-in-dark-mode primary-border-in-dark-mode primary-bg-hover-in-dark-mode">
              <img
                alt=""
                className="image-40"
                loading="lazy"
                src="/images/reload-ui-svgrepo-com.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
