export const autoExpandingTextArea = () => {
  let tag = document.querySelectorAll('textarea');

  for (let i = 0; i < tag.length; i++) {
    tag[i].addEventListener('paste', autoExpand);
    tag[i].addEventListener('input', autoExpand);
    tag[i].addEventListener('keyup', autoExpand);
  }

  function autoExpand(e, el) {
    var el = el || e.target;
    var paddingTop = parseInt(
      window.getComputedStyle(el, null).getPropertyValue('padding-top')
    );
    var paddingBottom = parseInt(
      window.getComputedStyle(el, null).getPropertyValue('padding-bottom')
    );

    const height = el.scrollHeight - (paddingTop + paddingBottom);
    if (height > 41 && height < 400) {
      el.style.height = 'auto';
      el.style.height = height + 'px';
    }
  }

  window.addEventListener('load', expandAll);
  window.addEventListener('resize', expandAll);

  function expandAll(e) {
    var tag = document.querySelectorAll('textarea');
    for (var i = 0; i < tag.length; i++) {
      autoExpand(e, tag[i]);
    }
  }
};
