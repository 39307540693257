import { useState, useEffect, useRef } from 'react';
import { api } from '@iblai/ibl-web-react-common';
import { useSelector } from 'react-redux';

export default function useUserItem(user) {
  const [showAdminMenu, setShowAdminMenu] = useState(false);
  const [role, setRole] = useState(user?.is_admin ? 'Admin' : 'Student');

  const auth = useSelector((state) => state.auth.data);

  const adminMenuRef = useRef();

  const handleClickOutsideMenu = (event) => {
    if (
      showAdminMenu &&
      adminMenuRef.current &&
      !adminMenuRef.current.contains(event.target)
    ) {
      setShowAdminMenu(false);
    }
  };

  const handleUserRoleUpdateSuccess = (response) => {};

  const handleUpdateRole = (user, role) => {
    const isAdmin = role === 'admin';
    const data = {
      username: user.username,
      is_admin: isAdmin,
      key: auth?.tenant?.key,
    };
    api.ibldmplatform.createCoreUsersPlatforms(
      data,
      handleUserRoleUpdateSuccess,
      (error) => console.log(error)
    );
  };

  const handleUpdateUserStatusSuccess = (response) => {};

  const handleActiveStatusToggle = (user) => {
    const data = {
      username: user.username,
      active: !user.active,
      key: auth?.tenant?.key,
    };
    api.ibldmplatform.createCoreUsersPlatforms(
      data,
      handleUpdateUserStatusSuccess,
      (error) => console.log(error)
    );
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideMenu);
    };
  }, []);

  return {
    role,
    adminMenuRef,
    setRole,
    setShowAdminMenu,
    showAdminMenu,
    handleUpdateRole,
    handleActiveStatusToggle,
  };
}
