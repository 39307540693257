import { useState } from 'react';
import { useSelector } from 'react-redux';

export default function useEditMentorTabs() {
  const edit = useSelector((state) => state.mentors.edit);
  const [tab, setTab] = useState(edit?.activeTab || 'settings');
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const tabs = [
    { key: 'settings', title: 'Settings' },
    { key: 'llm', title: 'LLM' },
    { key: 'prompts', title: 'Prompts' },
  ];

  if (editMentor?.can_use_tools) {
    tabs.push({ key: 'tools', title: 'Tools' });
  }

  tabs.push({ key: 'datasets', title: 'Datasets' });
  tabs.push({ key: 'embed', title: 'Embed' });

  return { tab, setTab, tabs };
}
